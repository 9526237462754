import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../../common/loader/FullPageLoader";


const Header = () => {
  const navigate = useNavigate();

  const [ loader, showLoader, hideLoader] = FullPageLoader();
  const data = {
    auth_token: localStorage.getItem('auth_token'),
    user_id: localStorage.getItem('user_id'),
    user_role: localStorage.getItem('user_role')
  }

  const handleOnLogout = (e) => {
    showLoader();
    axios.post('/api/user/logout', data).then(res => {
      if (res.data.status_code === 200) {
        hideLoader();
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_role');
        Swal.fire({ position: 'top-end', icon: 'success', title: res.data.message, showConfirmButton: false, timer: 1500 });
        navigate('/');
      } else {
        hideLoader();
      }
    }).catch(error => {
      console.log(error)
    });
  }
  return (
    <header id="header" className="navbar-static-top">
      <div className="topnav">
        <div className="container">
          <ul className="quick-menu pull-left">
            {localStorage.getItem('auth_token') ?
              <li>
                <Link to="/dashboard">MY ACCOUNT</Link>
              </li> : ""
            }
          
          </ul>
          <ul className="quick-menu pull-right">
            <li>
              {localStorage.getItem('auth_token') ?
                <Link to="/login" onClick={handleOnLogout} className="soap-popupbox text-danger">
                  LOGOUT
                </Link>
                : <Link to="/login" className="soap-popupbox">
                  LOGIN
                </Link>
              }
            </li>
            <li>
              <Link to="/register" className="soap-popupbox">
                SIGNUP
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-header">
        <a
          href="#mobile-menu-01"
          data-toggle="collapse"
          className="mobile-menu-toggle"
        >
          Mobile Menu Toggle
        </a>
        <div className="container">
          <h1 className="navbar-brand" style={{padding: 0}}>
            <Link to="/" title="Rentalcar - home">
            {/* <img style={{borderRadius: '50%'}} width={114} height={60} src="http://localhost:3000/static/media/logo3.362e015fe692d280c675.jpg" alt="rentalcar" /> */}
            <img style={{borderRadius: '50%'}} width={114} height={64} src={"/images/logo3.jpg"} alt="rentalcar" />

            </Link>
          </h1>
          <nav id="main-menu" role="navigation">
            <ul className="menu">
              <li className="menu-item-has-children">
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item-has-children">
                <Link to="/car">Cars</Link>
                {/* <ul>
                  <li>
                    <Link to="/car">Cars List</Link>
                  </li>
                  <li>
                    <Link to="/car-details">Detailed</Link>
                  </li>
                  <li>
                    <Link to="/booking">Booking</Link>
                  </li>
                  <li>
                    <Link to="/thankyou">Thank You</Link>
                  </li>
                </ul> */}
              </li>
            </ul>
          </nav>
        </div>
        <nav id="mobile-menu-01" className="mobile-menu collapse">
          <ul id="mobile-primary-menu" className="menu">
            <li className="menu-item-has-children">
              <Link to="/">Home</Link>
              {/* <ul>
                <li>
                  <a href="index.html">Home Layout 1</a>
                </li>
                <li>
                  <a href="homepage2.html">Home Layout 2</a>
                </li>
                <li>
                  <a href="homepage3.html">Home Layout 3</a>
                </li>
                <li>
                  <a href="homepage4.html">Home Layout 4</a>
                </li>
                <li>
                  <a href="homepage5.html">Home Layout 5</a>
                </li>
                <li>
                  <a href="homepage6.html">Home Layout 6</a>
                </li>
                <li>
                  <a href="homepage7.html">Home Layout 7</a>
                </li>
                <li>
                  <a href="homepage8.html">Home Layout 8</a>
                </li>
                <li>
                  <a href="homepage9.html">Home Layout 9</a>
                </li>
                <li>
                  <a href="homepage10.html">Home Layout 10</a>
                </li>
                <li>
                  <a href="homepage11.html">Home Layout 11</a>
                </li>
              </ul> */}
            </li>
            <li className="menu-item-has-children">
              <a href="/car">Cars</a>
              {/* <ul>
                <li>
                  <a href="car-index.html">Home Cars</a>
                </li>
                <li>
                  <a href="car-list-view.html">List View</a>
                </li>
                <li>
                  <a href="car-grid-view.html">Grid View</a>
                </li>
                <li>
                  <a href="car-block-view.html">Block View</a>
                </li>
                <li>
                  <a href="car-detailed.html">Detailed</a>
                </li>
                <li>
                  <a href="car-booking.html">Booking</a>
                </li>
                <li>
                  <a href="car-thankyou.html">Thank You</a>
                </li>
              </ul> */}
            </li>
          </ul>
        </nav>
      </div>
      <div id="travelo-signup" className="travelo-signup-box travelo-box">
        <div className="login-social">
          <a href="#" className="button login-facebook">
            <i className="soap-icon-facebook" />
            Login with Facebook
          </a>
          <a href="#" className="button login-googleplus">
            <i className="soap-icon-googleplus" />
            Login with Google+
          </a>
        </div>
        <div className="seperator">
          <label>OR</label>
        </div>
        <div className="simple-signup">
          <div className="text-center signup-email-section">
            <a href="#" className="signup-email">
              <i className="soap-icon-letter" />
              Sign up with Email
            </a>
          </div>
          <p className="description">
            By signing up, I agree to Rentalcar's Terms of Service, Privacy
            Policy, Guest Refund olicy, and Host Guarantee Terms.
          </p>
        </div>
        <div className="email-signup">
          <form>
            <div className="form-group">
              <input
                type="text"
                className="input-text full-width"
                placeholder="first name"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="input-text full-width"
                placeholder="last name"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="input-text full-width"
                placeholder="email address"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="input-text full-width"
                placeholder="password"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="input-text full-width"
                placeholder="confirm password"
              />
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" /> Tell me about Travelo news
                </label>
              </div>
            </div>
            <div className="form-group">
              <p className="description">
                By signing up, I agree to Rentalcar's Terms of Service, Privacy
                Policy, Guest Refund Policy, and Host Guarantee Terms.
              </p>
            </div>
            <button type="submit" className="full-width btn-medium">
              SIGNUP
            </button>
          </form>
        </div>
        <div className="seperator" />
        <p>
          Already a Rentalcar member?{" "}
          <a href="#travelo-login" className="goto-login soap-popupbox">
            Login
          </a>
        </p>
      </div>
      <div id="travelo-login" className="travelo-login-box travelo-box">
        <div className="login-social">
          <a href="#" className="button login-facebook">
            <i className="soap-icon-facebook" />
            Login with Facebook
          </a>
          <a href="#" className="button login-googleplus">
            <i className="soap-icon-googleplus" />
            Login with Google+
          </a>
        </div>
        <div className="seperator">
          <label>OR</label>
        </div>
        <form>
          <div className="form-group">
            <input
              type="text"
              className="input-text full-width"
              placeholder="email address"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="input-text full-width"
              placeholder="password"
            />
          </div>
          <div className="form-group">
            <a href="#" className="forgot-password pull-right">
              Forgot password?
            </a>
            <div className="checkbox checkbox-inline">
              <label>
                <input type="checkbox" /> Remember me
              </label>
            </div>
          </div>
        </form>
      
        <div className="seperator" />
        <p>
          Don't have an account?{" "}
          <a href="#travelo-signup" className="goto-signup soap-popupbox">
            Sign up
          </a>
        </p>
      </div>
      {loader}
    </header>
  );
};

export default Header;
