import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Sidebar from "../layout/Sidebar";
import Breadcrumb from "../layout/Breadcrumb";
import FullPageLoader from '../../common/loader/FullPageLoader';
import FrontendHocComponent from "../hoc/FrontendHoc";


const ViewProfile = () => {


  const location = useLocation();
  const [loader, showLoader, hideLoader] = FullPageLoader();

  const [userProfileData, setUserProfileData] = useState([]);

  const manageProfile = location.state ? location.state.page_name : '';

  // destructuring
  const {
    email,
    first_name,
    last_name,
    phone_number,
    dob,
    street_address,
    city,
    zipcode,
    country,
    image,
  } = userProfileData;

  let userId = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchUserData = () => {
      showLoader();
      axios.get("/sanctum/csrf-cookie").then(() => {
        axios.get(`/api/get/profile/${userId}`).then((res) => {
          hideLoader();
          setUserProfileData(res.data.result);
        });
      });
    };
    fetchUserData();
  }, []);


  return (
    <>
      {/* ========== breadcrum ==================  */}
      <Breadcrumb />
      <section id="content" className="gray-area">
        <div className="container">
          <div id="main">
            <div className="tab-container full-width-style arrow-left dashboard">
              {/* ========== sidebar ==================  */}
              <Sidebar />

              <div className="tab-content">
                <div className="view-profile tab-pane fade active in" id="profile">
                  <article className="image-box style2 box innerstyle personal-details">
                    <figure>
                      <a title="" href="#">
                        <img
                          width={270}
                          height={263}
                          alt=""
                          src={
                            image ? image : "images/shortcodes/team/th.jpg"
                          }
                        />
                      </a>
                    </figure>
                    <div className="details">
                      <Link
                        to={`/edituser/${userId}`}
                        className="button btn-mini pull-right edit-profile-btn"
                      >
                        EDIT PROFILE
                      </Link>
                      <h2 className="box-title fullname">
                        {first_name + " " + last_name}
                      </h2>
                      <dl className="term-description">
                        <dt>user name:</dt>
                        <dd>{email}</dd>
                        <dt>first name:</dt>
                        <dd>{first_name}</dd>
                        <dt>last name:</dt>
                        <dd>{last_name}</dd>
                        <dt>phone number:</dt>
                        <dd>{phone_number}</dd>
                        <dt>Date of birth:</dt>
                        <dd>{dob}</dd>
                        <dt>Street Address and number:</dt>
                        <dd>{street_address}</dd>
                        <dt>Town / City:</dt>
                        <dd>{city}</dd>
                        <dt>ZIP code:</dt>
                        <dd>{zipcode}</dd>
                        <dt>Country:</dt>
                        <dd>{country}</dd>
                      </dl>
                    </div>
                  </article>
                  <hr />
                  <h2>About You</h2>
                  <div className="intro">
                    <p>
                      Vestibulum tristique, justo eu sollicitudin sagittis,
                      metus dolor eleifend urna, quis scelerisque purus quam
                      nec ligula. Suspendisse iaculis odio odio, ac vehicula
                      nisi faucibus eu. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit. Suspendisse posuere semper
                      sem ac aliquet. Duis vel bibendum tellus, eu hendrerit
                      sapien. Proin fringilla, enim vel lobortis viverra,
                      augue orci fringilla diam, sed cursus elit mi vel lacus.
                      Nulla facilisi. Fusce sagittis, magna non vehicula
                      gravida, ante arcu pulvinar arcu, aliquet luctus arcu
                      purus sit amet sem. Mauris blandit odio sed nisi
                      porttitor egestas. Mauris in quam interdum purus
                      vehicula rutrum quis in sem. Integer interdum lectus at
                      nulla dictum luctus. Sed risus felis, posuere id
                      condimentum non, egestas pulvinar enim. Praesent pretium
                      risus eget nisi ullamcorper fermentum. Duis lacinia nisi
                      ac rhoncus vestibulum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loader}
    </>

  )
}
export default FrontendHocComponent(ViewProfile);