// npm packages
import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";

// layout
import Breadcrumb from './layout/Breadcrumb';
import FullPageLoader from '../common/loader/FullPageLoader';
import FrontendHocComponent from './hoc/FrontendHoc';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import "./style.css";



const Booking = () => {

    const Location = useLocation();
    const { id, carDatas } = Location?.state;
    let perdayPrice = sessionStorage.getItem("kmPrice");
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('')
    const [cashAmount, setCashAmount] = useState(null)
    

    // Destructring the api response datas
    const {
        car_image,
        car_name,
        car_type,
        mileage,

    } = carDatas


    let jsonTripDetails = localStorage.getItem('tripData');
    let userId = localStorage.getItem('user_id');
    let dstnc = localStorage.getItem('distance');
    let tripDetails = JSON.parse(jsonTripDetails);

    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [bookingData, setBookingData] = useState({
        user_id: userId,
        car_info_id: id,
        booking_days: 20,
        total_amount: perdayPrice,
        token_amount: "",
        pending_balance: perdayPrice - cashAmount,
        pick_up_location: tripDetails?.pick_up_place,
        drop_off_location: tripDetails?.drop_off_place,
        pick_up_date: tripDetails?.pick_up_date,
        drop_off_date: tripDetails?.drop_off_date,
        pick_up_time: tripDetails?.pick_up_time,
        drop_off_time: tripDetails?.drop_off_time,
        adults: tripDetails?.adults,
        kids: tripDetails?.kids,
        lat: 0,
        long: 0,
        distance: parseFloat(dstnc).toFixed(2),
        payment_type: paymentMethod,
        deposit_amount: cashAmount,
        taxes_and_fees: "",
        status: 1,

    })


    const handleCashAmountChange = (e) => {
        const enteredAmount = parseFloat(e.target.value);
        if (!isNaN(enteredAmount) && enteredAmount >= 0) {
            setCashAmount(enteredAmount);
            const pendingBalance = perdayPrice - enteredAmount;
            setBookingData((prevState) => ({
                ...prevState,
                total_amount: perdayPrice,
                pending_balance: pendingBalance.toFixed(2),
                deposit_amount: enteredAmount,
            }));
        }
    };
    

    useEffect(() => {
        if (id === undefined) {
            navigate('/car')
        }
    }, [])


    /**
    * Method to handle Booking 
    */
    const handleBooking = (paymentId) => {
        paymentId && setBookingData(prevState => ({
            ...prevState,
            payment_id: paymentId,
        }));
        showLoader();
        axios.get("/sanctum/csrf-cookie").then(() => {
            axios.post(`/api/booking`, bookingData).then((res) => {
                if (res.data) {
                    hideLoader();
                    Swal.fire({
                        title: "success",
                        timer: 1000,
                        position: 'top-right',
                        text: res.data.message,
                    });
                    navigate('/dashboard')

                }
            });
        });
    }



    const handleSubmit = (e) => {
        e.preventDefault();

        var options = {
            key: "rzp_test_bNjNy4djzxFOok",
            key_secret: "fGSuCzEGPPqwe0AePWbiV1w0",
            amount: 1000,
            currency: "INR",
            name: "STARTUP_PROJECTS",
            description: "for testing purpose",
            handler: function (response) {
                handleBooking(response?.razorpay_payment_id);
                console.log(response?.razorpay_payment_id);
            },
            prefill: {
                name: "Velmurugan",
                email: "mvel1620r@gmail.com",
                contact: "8288873858"
            },
            notes: {
                address: "Razorpay Corporate office"
            },
            theme: {
                color: "#3399cc"
            }
        };
        var pay = new window.Razorpay(options);
        pay.open();
    }

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value)
        setBookingData(prevState => ({
            ...prevState,
            payment_type: event.target.value,
        }));
        if (event.target.value === 'other') {
            setPaymentMethod('other')
            setBookingData(prevState => ({
                ...prevState,
                payment_type: event.target.value,
            }));
            handleSubmit(event);
        }
    }

    const handleKeyDown = (e) => {
        if (
            (e.key >= '0' && e.key <= '9') ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'Tab' ||
            e.key === ' ' ||
            e.key === 'Backspace'
        ) {
            return;
        } else {
            e.preventDefault();
        }
    };



    return (
        <>
            <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    <div className="row">
                        <div className="sidebar col-sms-6 col-sm-4 col-md-3">
                            <div className="booking-details travelo-box">
                                <h4>Booking Details</h4>
                                <article className="car-detail">
                                    <figure className="clearfix">
                                        <a title="" href={`/car-details/${id}`} className="middle-block" style={{ position: 'relative' }}><img className="middle-item" alt="" src={car_image} style={{ position: 'absolute', top: '50%', marginTop: '-37.5px', left: '50%', marginLeft: '-57px' }} /></a>
                                        <div className="travel-title">
                                            <h5 className="box-title">{car_name}<small>{car_type}</small></h5>
                                            {/* <a href="car-detailed.html" className="button">EDIT</a> */}
                                        </div>
                                    </figure>
                                    <div className="details">
                                        <div className="icon-box style11 full-width">
                                            <div className="icon-wrapper">
                                                <i className="soap-icon-departure" />
                                            </div>
                                            <dl className="details">
                                                <dt className="skin-color">Date</dt>
                                                <dd>{tripDetails?.pick_up_date} to {tripDetails?.drop_off_date}</dd>
                                            </dl>
                                        </div>
                                        <div className="icon-box style11 full-width">
                                            <div className="icon-wrapper">
                                                <i className="soap-icon-departure" />
                                            </div>
                                            <dl className="details">
                                                <dt className="skin-color">Time</dt>
                                                <dd>{tripDetails?.pick_up_time} to {tripDetails?.drop_off_time}</dd>
                                            </dl>
                                        </div>
                                        <div className="icon-box style11 full-width">
                                            <div className="icon-wrapper">
                                                <i className="soap-icon-departure" />
                                            </div>
                                            <dl className="details">
                                                <dt className="skin-color">Location</dt>
                                                <dd>{tripDetails?.pick_up_place} to {tripDetails?.drop_off_place}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </article>
                                <h4>Other Details</h4>
                                <dl className="other-details">
                                    <dt className="feature">Mileage included:</dt><dd className="value">{mileage}</dd>
                                    <dt className="feature">Per day price:</dt><dd className="value">₹{perdayPrice}</dd>
                                    <dt className="feature">taxes and fees:</dt><dd className="value">₹</dd>
                                    <dt className="total-price">Total Price</dt><dd className="total-price-value">₹{perdayPrice}</dd>
                                </dl>
                            </div>
                        </div>
                        <div id="main" className="col-sms-6 col-sm-8 col-md-9">
                            <div className="booking-section travelo-box">
                                <form className="booking-form" method="post" action="#">
                                    <div style={{ paddingBottom: 50 }} className="card-information">
                                        <img height={350} width={840} src='images/banner-6.png' alt='booking-page' />
                                    </div>

                                    <div className="payment-form">
                                        <div className="radio-group">
                                            <label className="radio-label">
                                                <input
                                                    type="radio"
                                                    value="cash"
                                                    checked={paymentMethod === 'cash'}
                                                    onChange={handlePaymentMethodChange}
                                                />
                                                Pay with Cash
                                            </label>
                                            <label className="radio-label">
                                                <input
                                                    type="radio"
                                                    value="other"
                                                    checked={paymentMethod === 'other'}
                                                    onChange={handlePaymentMethodChange}
                                                />
                                                Pay with Other Methods
                                            </label>
                                        </div>
                                        <div className={`input-field ${paymentMethod === 'cash' ? 'show-input' : ''}`}>
                                            <label htmlFor="cash-amount">Cash Amount:</label>
                                            <input
                                                type="text"
                                                className="input-text"
                                                id="cash-amount"
                                                value={cashAmount}
                                                onChange={handleCashAmountChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                    {cashAmount && <div className="form-group row">
                                        <div className="col-sm-6 col-md-5">
                                            <button type='button' className="button yellow full-width uppercase btn-large " onClick={() => handleBooking("")}>CONFIRM BOOKING</button>
                                        </div>
                                    </div>}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                {loader}
            </section>
        </>

    );
};
export default FrontendHocComponent(Booking);


