import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import FullPageLoader from "../../common/loader/FullPageLoader";
import Breadcrumb from "../layout/Breadcrumb";
import Sidebar from "../layout/Sidebar";

const Document = () => {
  const userId = localStorage.getItem('user_id');


  const navigate = useNavigate();

  const [loader, showLoader, hideLoader] = FullPageLoader();

  const [image, setImage] = useState([]);

  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob: "",
    street_address: "",
    state: "",
    district: "",
    city: "",
    country: "",
    zipcode: "",
    status: "",
  });

  const [isDisabled, setIsDisabled] = useState(false);

  // destructuring
  const {
    id,
    email,
    first_name,
    last_name,
    phone_number,
    dob,
    street_address,
    city,
    zipcode,
    country,
    state,
    district,
    status,
    user_type,
    phone_code
  } = userData;

  const fetchUserData = () => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`/api/get/profile/${userId}`).then((res) => {
        setUserData(res.data.result);
      });
    });
  };

  useEffect(() => {
    fetchUserData();
  }, [null]);

  const handleImage = (e) => {
    setImage({ image: e.target.files[0] });
  };

  const handleInputChange = ({
    target: { name = "", value = "" || [] } = {},
  }) => {
    setUserData({ ...userData, [name]: value });
  };

  const updateUserData = async (e) => {
    showLoader();
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData();

    formData.append("user_id", id);
    formData.append("user_type", user_type)
    formData.append("image", image.image);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("phone_number", phone_number);
    formData.append("phone_code", phone_code);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("city", city);
    formData.append("street_address", street_address);
    formData.append("zipcode", zipcode);
    formData.append("status", status);

    await axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`/api/update/profile/${userId}`, formData).then((res) => {
        if (res.data.result) {
          hideLoader();
          Swal.fire({ position: 'top-end', icon: 'success', title: res.data.message, showConfirmButton: true, timer: 1500 });
          //navigate('/dashboard');
          //navigate(`/dashboard/`,{page_name:'edit_profile'});
          navigate('/dashboard/', { replace: true, state: { page_name: 'edit_profile' } });
        }
        else {
          hideLoader();
          Swal.fire({ position: 'top-end', icon: 'error', title: res.data.message, showConfirmButton: false, timer: 1500 });

        }
        setIsDisabled(false);
      })
    });
  };

  return (
    <>
      {/* ========== breadcrum ==================  */}
      <Breadcrumb />
      <section id="content" className="gray-area">
        <div className="container">
          <div id="main" className="col-sm-12 col-md-12">
            <div className="tab-container full-width-style arrow-left dashboard">
              {/* ========== sidebar ==================  */}
              <Sidebar />
              <div className="tab-content">
                <div id="dashboard" className='tab-pane fade active in'>
                  <form onSubmit={(e) => updateUserData(e)} className="booking-form">
                    <div className="person-information">
                      <h2>Driver Documentation</h2>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>DRIVING LICENSE</label>
                          <input
                            type="file"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="image"
                            onChange={handleImage}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>EXPERIENCE LETTER</label>
                          <input
                            type="file"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="image"
                            onChange={handleImage}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>RC DOC</label>
                          <input
                            type="file"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="image"
                            onChange={handleImage}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>POLLUTION DOC</label>
                          <input
                            type="file"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="image"
                            onChange={handleImage}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                      </div>
                      <div className="form-group row ">
                        <div
                          className="col-md-12"
                          style={{ marginTop: "25px" }}
                        >
                          <label>ADDITIONAL INFORMATION</label>
                          <textarea
                            className="full-width"
                            rows={10}
                            placeholder="Please provide any additional information regarding your hotel requirements."
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={`checkbox status-checkbox ${status === "1" ? "checked" : ""
                            }`}
                        >
                          <label>
                            <input
                              type="checkbox"
                              name="status"
                              tabIndex={3}
                              value={status === "1" ? "0" : "1"}
                              onChange={handleInputChange}
                            />
                            Status
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row " style={{ marginTop: "25px" }}>
                      <div className="col-sm-6 col-md-12">
                        <button type="submit" className="full-width btn-large" disabled={isDisabled}>
                          UPDATE YOUR ACCOUNT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {loader}
          </div>
        </div>
      </section>
    </>
  );
};
export default Document;
