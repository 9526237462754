// npm packages
import React from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import ComponentLoader from "../../common/loader/ComponentLoader";


const FrontendHocComponent = (OriginalComponent) => {
    return (props) => {
        const auth_token = localStorage.getItem('auth_token');
       

        const renderComponent = () => {


            if (auth_token) {
                return ( 
                
                <OriginalComponent {...props} /> )
            }
             else {
                return (
                  
                    Swal.fire({
                        icon: 'error',
                        title: 'Sorry!!!',
                        text: 'You are not logged In yet!',
                        confirmButtonColor: '#3090C7',
                        footer: '<a href="/login">Click here to login first!</a>'
                      }),
                     <Navigate to="/" replace={true} />
                )  
            }
        };
        return <>{renderComponent()}</>;
    };
};
export default FrontendHocComponent;