import React, { useState } from "react";

/**
 * 
 * @param {array} param0 
 * @param {function} param1
 * @returns node
 */
const TableHeader = ({headers, onSorting}) => {

    // States
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");

    /**
     * Method to handle the  change
     * @param {string} field 
     */
    const onSortingChange = field => {
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    }  
    return (
        <thead>
            <tr>
                {headers.map(({ name, field, sortable }) => (

                    <th key={name}
                        onClick={() => sortable ? onSortingChange(field) : null}
                    >
                        {name}

                        {
                            sortingField && sortingField === field && (

                                <>
                                    {
                                        sortingOrder === "asc"
                                            ? <i className="fas fa-arrow-down"></i>
                                            : <i className="fas fa-arrow-up"></i>
                                    }
                                </>
                            )}

                    </th>

                ))}
            </tr>
        </thead>
    );
}
export default TableHeader;