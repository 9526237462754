import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Sidebar from "./layout/Sidebar";
import FullPageLoader from "../common/loader/FullPageLoader";
import SearchUser from '../data_table/search';
import PaginationComponent from '../data_table/pagination';
import { Link } from "react-router-dom";
import Breadcrumb from "./layout/Breadcrumb";
import FrontendHocComponent from "./hoc/FrontendHoc";

const ViewBookingDetails = () => {
    const [data, setData] = useState([]);
    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const ITEMS_PER_PAGE = 5;

    const userId = localStorage.getItem("user_id");

    const fetchBookingDetails = () => {
        showLoader();
        axios.post(`/api/get/user/booking`, {user_id: userId}).then((res) => {
            if (res.data.result) {
                const userBookings = res.data.result;
                if (userBookings.length > 0) {
                    hideLoader();
                    const sortedData = userBookings.sort((a, b) => a.id - b.id);
                    setData(sortedData);
                    setTotalItems(sortedData.length);
                } else {
                    hideLoader();
                    console.warn("No bookings found for the user");
                }
            }
            hideLoader();
        });
    };

    useEffect(() => {
        fetchBookingDetails();
    }, []);

    const filteredData = useMemo(() => {
        let computedData = data;
        if (search) {
            computedData = computedData.filter(
                (booking) =>
                    booking?.user_id.toString().includes(search.toLowerCase()) ||
                    booking?.car_info_id.toString().includes(search.toLowerCase()) ||
                    booking?.pick_up_location.toLowerCase().includes(search.toLowerCase()) ||
                    booking?.drop_off_location.toLowerCase().includes(search.toLowerCase())
                // Add more fields for searching as needed
                // ...
            )
        }
        setTotalItems(computedData.length);

        return computedData.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [data, search, currentPage]);


    return (
        <>
         <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    {/* ... Your existing code ... */}
                    <div className="tab-container full-width-style arrow-left dashboard">
                        <Sidebar />
                        {data.length === 0 ? (
                            <h1 style={{textAlign: "center", paddingTop: 100}}>You didn't book any car yet!!</h1>
                        ) : (
                            <div className="tab-content">
                                <div id="dashboard" className="tab-pane fade active in">
                                    <h2>BOOKING DETAILS</h2>
                                    <div className="filter-section clearfix">
                                        <div className="pull-right col-md-12 action">
                                            <SearchUser
                                                onSearch={(value) => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table">
                                        <div className="table-container" style={{ marginTop: '28px' }}>
                                            <table className="table table-bordered" width="100%">
                                                <thead>
                                                    <th>S.No</th>
                                                    <th>Car Info Id</th>
                                                    <th>Pick Up Location</th>
                                                    <th>Drop Off Location</th>
                                                    <th>Action</th>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((booking, index) => (
                                                        <tr key={index}>
                                                            <td>{booking?.id}</td>
                                                            <td>{booking?.car_info_id}</td>
                                                            <td>{booking?.pick_up_location}</td>
                                                            <td>{booking?.drop_off_location}</td>
                                                            <td className="list-action-sec">
                                                                <Link to={`/car-booking-details/${booking?.id}`} className="btn btn-info btn-circle btn-sm mr-2" title="View booking details">
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <PaginationComponent
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {loader}
            </section>
        </>
    );
};

export default FrontendHocComponent(ViewBookingDetails);
