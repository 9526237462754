import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputRange from "react-input-range";

let carTypes = ['Economy', 'Full Size', 'Mini', 'Luxury', 'Intermediate']

const Filter = ({carsList, dataCounts}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [isActive, setIsActive] = useState(false);
    const [price, setPrice] = useState(40);
    const [selectedTypes, setSelectedTypes] = useState([]);

    // setCarsList(carsList?.filter(car => selectedTypes?.includes(car?.car_type)));
    const handleInput = (e) => {
        setPrice(e.target.value);
    };

    const handleClick = (event) => {
        let str = location.pathname;
        let parts = str.split('/');
        let tabName = parts[parts.length - 2];
        let tabActive = event ? event : tabName === 'admin' ? 'dashboard' : tabName;
        setIsActive(tabActive);
    };
    useEffect(() => {
        // Filter the cars based on selectedTypes and set to filteredCars state
        
          const newFilteredCars = carsList.filter(car => selectedTypes.includes(car.car_type));
          navigate('/car', { state: newFilteredCars });
       
      }, [selectedTypes]);

    const handleCarTypeSelect = (e) => {

        const { value, checked } = e.target;

        setSelectedTypes(prevSelectedTypes => {
            if (checked) {
                return [...prevSelectedTypes, value];
            } else {
                return prevSelectedTypes?.filter(type => type !== value);
            }
        });
    };


    useEffect(() => {
        handleClick();
    }, [])





    return (
        <div className="col-sm-4 col-md-3">
            <h4 className="search-results-title"><i className="soap-icon-search" /><b>{dataCounts}</b> results</h4>
            <div className="toggle-container filters-container">
                {/* <div className="panel style1 arrow-right">
                    <h4 className="panel-title">
                        <a data-toggle="collapse" href="#price-filter" className="collapsed">Price</a>
                    </h4>

                    <div id="price-filter" className="panel-collapse collapse" aria-expanded="false">
                        <div className="panel-content">
                            <input type="range" onInput={handleInput} />
                        </div>
                    </div>

                </div> */}
                <div className="panel style1 arrow-right">
                    <h4 className="panel-title">
                        <a data-toggle="collapse" href="#cartype-filter" className="collapsed">Car Type</a>
                    </h4>
                    <div id="cartype-filter" className="panel-collapse collapse filters-container">
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '14px', cursor: 'pointer' }} className="panel-content ">

                            {
                                carTypes?.map((type) => {
                                    return (
                            <label key={type} >
                                <input type="checkbox" checked={selectedTypes.includes(type)} value={type} onChange={handleCarTypeSelect} />
                                {type}
                            </label>
                            )
                                })
                            }

                        </div>
                    </div>
                </div>
                {/* <div className="panel style1 arrow-right">
                    <h4 className="panel-title">
                        <a data-toggle="collapse" href="#car-rental-agent-filter" className="collapsed">Car Rental Agent</a>
                    </h4>
                    <div id="car-rental-agent-filter" className="panel-collapse collapse">
                        <div className="panel-content">
                            <ul className="check-square filters-option">
                                <li><a href="#">Fox Rent A Car<small>(08)</small></a></li>
                                <li><a href="#">Payless<small>(32)</small></a></li>
                                <li className="active"><a href="#">Ez rent a car<small>(227)</small></a></li>
                                <li><a href="#">Thrifty<small>(22)</small></a></li>
                                <li><a href="#">Enterprise<small>(18)</small></a></li>
                                <li><a href="#">Alamo<small>(29)</small></a></li>
                                <li><a href="#">Dollar<small>(12)</small></a></li>
                            </ul>
                            <a className="button btn-mini">MORE</a>
                        </div>
                    </div>
                </div>
                <div className="panel style1 arrow-right">
                    <h4 className="panel-title">
                        <a data-toggle="collapse" href="#car-preferences-filter" className="collapsed">Car Preferences</a>
                    </h4>
                    <div id="car-preferences-filter" className="panel-collapse collapse">
                        <div className="panel-content">
                            <ul className="check-square filters-option">
                                <li><a href="#">Passenger Quantity</a></li>
                                <li className="active"><a href="#">Satellite Navigation</a></li>
                                <li className="active"><a href="#">Air Conditioning</a></li>
                                <li><a href="#">Doors</a></li>
                                <li><a href="#">Diesel Vehicle</a></li>
                            </ul>
                            <a className="button btn-mini">MORE</a>
                        </div>
                    </div>
                </div>
                <div className="panel style1 arrow-right">
                    <h4 className="panel-title">
                        <a data-toggle="collapse" href="#modify-search-panel" className="collapsed">Modify Search</a>
                    </h4>
                    <div id="modify-search-panel" className="panel-collapse collapse">
                        <div className="panel-content">
                            <form method="post">
                                <div className="form-group">
                                    <label>pickup from</label>
                                    <input type="text" className="input-text full-width" placeholder="city, district, or specific airpot" defaultValue />
                                </div>
                                <div className="form-group">
                                    <label>pick-up date</label>
                                    <div className="datepicker-wrap">
                                        <input type="text" name="date_from" className="input-text full-width hasDatepicker" placeholder="mm/dd/yy" id="dp1655735776976" /><img className="ui-datepicker-trigger" src={"images/icon/blank.png"} alt="rentalcar" title="rentalcar" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>drop-off date</label>
                                    <div className="datepicker-wrap">
                                        <input type="text" name="date_to" className="input-text full-width hasDatepicker" placeholder="mm/dd/yy" id="dp1655735776977" /><img className="ui-datepicker-trigger" src={"images/icon/blank.png"} alt="rentalcar" title="rentalcar" />
                                    </div>
                                </div>
                                <br />
                                <button className="btn-medium icon-check uppercase full-width">search again</button>
                            </form>
                        </div>
                    </div> 
                </div>*/}
            </div>
        </div>


    );
};
export default Filter;


