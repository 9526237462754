// NPM Packages
import React, {useState} from 'react';

/**
 * Method to handle the search
 * @param {function} param0 
 * @returns node
 */
const SearchUser = ({onSearch}) => {
    const [search, setSearch] = useState("");

    /**
     * Method to handle the input change
     * @param {string} value 
     */
    const onInputChange = (value) => {
        setSearch(value);
        onSearch(value);
    }
    return (
        <input
            type="text"
            className="input-text pull-right"
            style={{ width: "209px" }}
            placeholder="Search"
            value={search}
            onChange={(e) => onInputChange(e.target.value)}
        />
    )
}
export default SearchUser;