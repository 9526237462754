// NPM Packages 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import AdminHocComponent from '../hoc/AdminHoc';

// components
import FullPageLoader from '../../common/loader/FullPageLoader';

/**
 * Method to show the single user detail
 * @returns node
 */
const ViewUser = () => {

    // method use to get id from the url
    const { id } = useParams();

    const [loader, showLoader, hideLoader] = FullPageLoader();

    // States
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);

    /**
     * Method to get single user detail
     * Component Did Mount
     */
    useEffect(() => {
       showLoader()
        // flag
        let isMounted = true;
        axios.get(`api/get/profile/${id}`).then(res => {

            if (isMounted) {
                if (res.data.result) {
                    hideLoader()
                    setUser(res.data.result);
                    setLoading(false);
                } else if (res.data.status === 'false') {
                    hideLoader()
                    Swal.fire("warning", res.data.message, "error");
                }
            }

        });
        return () => {
            isMounted = false;
        };
    }, [id]);


    if (loading) {
        return <div>{loader}</div>
    } else {

    }
    return (

        <div id="profile" className="tab-pane fade active in">
            <h2>USER DETAILS</h2>
            <div className="table">
                <table className="table table-bordered" width="100%">
                    <tbody>
                        <tr>
                            <th >User Name:</th>
                            <td>{user.first_name}  {user.last_name}</td>
                        </tr>
                        <tr>
                            <th >Email:</th>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <th >Street Address:</th>
                            <td>{user.street_address}</td>
                        </tr>
                        <tr>
                            <th >Street Address 2:</th>
                            <td>{user.street_address_2}</td>
                        </tr>
                        <tr>
                            <th >Country:</th>
                            <td>{user.country}</td>
                        </tr>
                        <tr>
                            <th >State:</th>
                            <td>{user.state}</td>
                        </tr>
                        <tr>
                            <th >City:</th>
                            <td>{user.city}</td>
                        </tr>
                        <tr>
                            <th >Mobile Number:</th>
                            <td>{user.phone_number}</td>
                        </tr>
                        <tr>
                            <th >Zipcode:</th>
                            <td>{user.zipcode}</td>
                        </tr>
                        <tr>
                            <th >User Type:</th>
                            <td>{user.user_type}</td>
                        </tr>

                        <tr>
                            <th>Status:</th>
                            <td className="center">
                                {user.status ? 'Active' : 'Inactive'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    )
}
export default AdminHocComponent(ViewUser);