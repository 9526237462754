import React, { useState,useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
const Sidebar = () => {

      const location = useLocation();
      //const manageProfile = location.state ? location.state.page_name : '';

    const [isActive, setIsActive] = useState(false);

    const handleClick = (event)  => {
        let str = location.pathname;
        let parts = str.split('/');
        let tabName = parts[parts.length - 1];
        let tabActive = event ? event : tabName;
        setIsActive(tabActive);
      };

      useEffect(() => {
        handleClick();
      }, [])

    return (
        <ul className="tabs">
            <li className={isActive === 'dashboard' ? 'active' : ''} onClick={() => handleClick('dashboard')}>
                <Link  to="/dashboard">
                    <i className="soap-icon-anchor circle" />
                    Dashboard
                </Link>
            </li>
            <li className={isActive === 'profile' ? 'active' : ''} onClick={() => handleClick('profile')}>
                <Link  to="/profile">
                    <i className="soap-icon-user circle" />
                    Users
                </Link>
            </li>
            <li className={isActive === 'carInfo' ? 'active' : ''} onClick={() => handleClick('carInfo')}>
                <Link  to="/carInfo">
                    <i className="soap-icon-car circle " />
                    CarsInfo
                </Link>
            </li>
            <li className={isActive === 'booking' ? 'active' : ''} onClick={() => handleClick('booking')}>
                <Link  to="/bookingdetails">
                    <i className="soap-icon-businessbag circle" />
                    Booking Details
                </Link>
            </li>
            <li className={isActive === 'setting' ? 'active' : ''} onClick={() => handleClick('setting')}>
                <Link  to="/setting">
                    <i className="soap-icon-settings circle" />
                    Settings
                </Link>
            </li>
            {/* <li className={isActive === 'cardinfo' ? 'active' : ''} onClick={() => handleClick('cardinfo')}>
                <a data-toggle="tab" href="#cardinfo">
                    <i className="soap-icon-card" />
                    Card Info
                </a>
            </li>
            <li className={isActive === 'documentation' ? 'active' : ''} onClick={() => handleClick('documentation')}>
                <Link  to="/documentation">
                    <i className="soap-icon-doc-plus" />
                    Documentation
                </Link>
            </li> */}
        </ul>

    )
}
export default Sidebar;