// Npm Packages
import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Component
import FullPageLoader from "../../common/loader/FullPageLoader";
import Breadcrumb from "../layout/Breadcrumb";
import Sidebar from "../layout/Sidebar";


/**
 * Method to handle the post car information.
 * @returns node
 */
const PostCar = () => {

    let userId = localStorage.getItem('user_id');
    const [loader, showLoader, hideLoader] = FullPageLoader();
    const navigate = useNavigate();

    // States
    const [car_image, setImage] = useState([]);

    const [isDisabled, setIsDisabled] = useState(false);

    const [carInfo, setCarInfo] = useState({
        user_id: userId,
        car_type: "Economy",
        car_name: "",
        car_number: "",
        car_model_number: "",
        seats: "4",
        fuel_type: "DIESEL",
        satellite_navigation: "NO",
        air_conditioning: "NO",
        automatic_transmission: "NO",
        how_many_year_old: "",
        longitude: "0",
        latitude: "0",
        location: "",
        mileage: "80",
        additional_information: "",
        status: "1",
        error: [],
    })

    /**
     * Method to handle the image change
     * @param {object} e 
     */
    const handleImage = (e) => {
        setImage({ image: e.target.files[0] });
    };


    /**
     * Method to handle the input field changes.
     */
    const handleOnChange = ({ target: { name = "", value = "" || [] } = {}, }) => {
        setCarInfo({ ...carInfo, [name]: value });
    }

    /**
     * Method to handle the post car information.
     */
    const handleOnSubmit = async (e) => {
        showLoader();
        e.preventDefault();
        setIsDisabled(true);
        const formData = new FormData();

        formData.append("user_id", carInfo.user_id);
        formData.append("car_image", car_image.image);
        formData.append("car_type", carInfo.car_type);
        formData.append("car_name", carInfo.car_name);
        formData.append("car_number", carInfo.car_number);
        formData.append("car_model_number", carInfo.car_model_number);
        formData.append("seats", carInfo.seats);
        formData.append("fuel_type", carInfo.fuel_type);
        formData.append("satellite_navigation", carInfo.satellite_navigation);
        formData.append("air_conditioning", carInfo.air_conditioning);
        formData.append("automatic_transmission", carInfo.automatic_transmission);
        formData.append("how_many_year_old", carInfo.how_many_year_old);
        formData.append("longitude", carInfo.longitude);
        formData.append("latitude", carInfo.latitude);
        formData.append("location", carInfo.location);
        formData.append("mileage", carInfo.mileage);
        formData.append("status", carInfo.status);
        formData.append("additional_information", carInfo.additional_information);

        await axios.get("/sanctum/csrf-cookie").then(() => {
            axios.post(`/api/post/car/info`, formData).then((res) => {
                if (res.data.success) {
                    hideLoader();
                    Swal.fire({ position: 'top-end', icon: 'success', title: "success", showConfirmButton: true, timer: 1500 });
                    navigate('/carInfo');
                    //navigate(`/dashboard/`,{page_name:'edit_profile'});
                    // navigate('/dashboard/', { replace: true, state: {page_name:'edit_profile'} });
                }
                else {
                    hideLoader();
                    Swal.fire({ position: 'top-end', icon: 'error', title: "error", showConfirmButton: false, timer: 1500 });
                    setCarInfo
                        ({
                            ...carInfo,
                            error: res.data.errors,
                        });
                }
                setIsDisabled(false);
            })
        });
    }

    return (

        <>

            {/* ========== breadcrum ==================  */}
            <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main" className="col-sm-12 col-md-12">
                        <div className="tab-container full-width-style arrow-left dashboard">
                            {/* ========== sidebar ==================  */}
                            <Sidebar />
                            <div className="tab-content">
                                <article className="image-box box innerstyle personal-details">
                                    <div className="details">
                                        <h2 className="box-title fullname">POST CAR </h2>
                                    </div>
                                </article>
                                <div id="carInfo" className='tab-pane fade active in'>
                                    <form onSubmit={(e) => handleOnSubmit(e)}>
                                        <input type="hidden" name="user_id" value={carInfo.user_id} />
                                        <div className="row">
                                            <div className="col-sm-4 col-md-6">
                                                <label>Car type</label>
                                                <div className="selector">
                                                    <select
                                                        className=""
                                                        name="car_type"
                                                        value={carInfo.car_type}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="">Economy</option>
                                                        <option value="Full Size">Full Size</option>
                                                        <option value="Luxury">Luxury</option>
                                                        <option value="Mini">Mini</option>
                                                        <option value="Intermediate">Intermediate</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.car_type}</span>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>Car Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control" placeholder="Enter your car name number"
                                                    name="car_name"
                                                    value={carInfo.car_name}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.car_name}
                                                </span>
                                            </div>
                                            <div className="col-sm-4 col-md-6">
                                                <label>Car Number</label>
                                                <input
                                                    type="text"
                                                    name="car_number"
                                                    className="form-control" placeholder="Enter your car number"
                                                    value={carInfo.car_number}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.car_number}
                                                </span>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>Car Model Number</label>
                                                <input
                                                    type="text"
                                                    name="car_model_number"
                                                    className="form-control" placeholder="Enter your car model number"
                                                    value={carInfo.car_model_number}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.car_model_number}
                                                </span>
                                            </div>
                                            <div className="col-sm-4 col-md-6">
                                                <label>Seats</label>
                                                <div className="selector">
                                                    <select
                                                        className="form-control"
                                                        name="seats"
                                                        value={carInfo.seats}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="7">7</option>
                                                        <option value="9">9</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.seats}</span>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>Fuel types</label>
                                                <div className="selector">
                                                    <select
                                                        className="form-control"
                                                        name="fuel_type"
                                                        value={carInfo.fuel_type}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="">DISEL</option>
                                                        <option value="PETROL">PETROL</option>
                                                        <option value="ELECTRIC">ELECTRIC</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.fuel_type}</span>
                                                </div>
                                            </div>


                                            <div className="col-sm-4 col-md-6">
                                                <label>Car Mileage</label>
                                                <input
                                                    type="text"
                                                    name="mileage"
                                                    className="form-control"
                                                    placeholder="Enter your car Mileage"
                                                    value={carInfo.mileage}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.mileage}
                                                </span>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    name="location"
                                                    className="form-control"
                                                    placeholder="Enter Location"
                                                    value={carInfo.location}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.location}
                                                </span>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>SATELLITE NAVIGATION</label>
                                                <div className="selector">
                                                    <select
                                                        className="form-control"
                                                        name="satellite_navigation"
                                                        value={carInfo.satellite_navigation}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="YES">YES</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.satellite_navigation}</span>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <label>AIR CONDITIONING</label>
                                                <div className="selector">
                                                    <select
                                                        className="form-control"
                                                        name="air_conditioning"
                                                        value={carInfo.air_conditioning}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="YES">YES</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.air_conditioning}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-6">
                                                <label>AUTOMATIC TRANSMISSION</label>
                                                <div className="selector">
                                                    <select
                                                        className="form-control"
                                                        name="automatic_transmission"
                                                        value={carInfo.automatic_transmission}
                                                        onChange={handleOnChange}
                                                    >
                                                        <option value="YES">YES</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                    <span className="custom-select full-width">{carInfo.automatic_transmission}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-6">
                                                <label>Car Image</label>
                                                <div className="selector">
                                                    <input
                                                        type="file"
                                                        name="car_image"
                                                        className="form-control"
                                                        placeholder="Car Image"
                                                        onChange={handleImage}
                                                    />
                                                    <span className="text-danger">
                                                        {carInfo.error.car_image}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-6">
                                                <label>HOW MANY YEAR OLD</label>
                                                <div className="selector">
                                                    <input
                                                        type="text"
                                                        name="how_many_year_old"
                                                        className="form-control"
                                                        placeholder="How many year old"
                                                        value={carInfo.how_many_year_old}
                                                        onChange={handleOnChange}
                                                    />
                                                    <span className="text-danger">
                                                        {carInfo.error.how_many_year_old}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <label>ADDITIONAL INFORMATION</label>
                                                <textarea
                                                    name="additional_information"
                                                    className="form-control"
                                                    rows={10}
                                                    placeholder="Please provide any additional information regarding your hotel requirements."
                                                    value={carInfo.additional_information}
                                                    onChange={handleOnChange}
                                                />
                                                <span className="text-danger">
                                                    {carInfo.error.additional_information}
                                                </span>
                                            </div>

                                            <div className="col-sm-4 col-md-6">
                                                <div
                                                    className={`checkbox ${carInfo.status === "1"
                                                        ? "checked"
                                                        : "!checked"
                                                        }`}
                                                >
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="status"
                                                            tabIndex={3}
                                                            value={
                                                                carInfo.status === "1"
                                                                    ? "0"
                                                                    : "1"
                                                            }
                                                            onChange={handleOnChange}
                                                        />
                                                        Status
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 pull-right">
                                                <button type="submit" className="button btn-large dark-blue1 btn-admin-panel">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                    {loader}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default PostCar;
