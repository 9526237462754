import React, { useState,useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
const Sidebar = () => {

      const location = useLocation();
      //const manageProfile = location.state ? location.state.page_name : '';

    const [isActive, setIsActive] = useState(false);

    const handleClick = (event)  => {
        let str = location.pathname;
        let parts = str.split('/');
        let tabName = parts[parts.length - 2];
        let tabActive = event ? event : tabName === 'admin' ? 'dashboard' : tabName;
        setIsActive(tabActive);
      };

      useEffect(() => {
        handleClick();
      }, [])

    

    return (
        <ul className="tabs">
            <li className={isActive === 'dashboard' || !isActive ? 'active' : ''} onClick={() => handleClick('dashboard')}>
                <Link  to="/admin/dashboard">
                    <i className="soap-icon-anchor circle" />
                    Dashboard
                </Link>
            </li>
            <li className={isActive === 'user' ? 'active' : ''} onClick={() => handleClick('user')}>
                <Link  to="/admin/user/index">
                    <i className="soap-icon-user circle" />
                    Users
                </Link>
            </li>
            <li className={isActive === 'car' ? 'active' : ''} onClick={() => handleClick('car')}>
                <Link  to="/admin/car/index">
                    <i className="soap-icon-car circle " />
                    CarsInfo
                </Link>
            </li>
            <li className={isActive === 'fare' ? 'active' : ''} onClick={() => handleClick('fare')}>
                <Link  to="/admin/car/fare">
                    <i className="fa fa-dollar circle" />
                    Fare
                </Link>
            </li>
            <li className={isActive === 'booking' ? 'active' : ''} onClick={() => handleClick('booking')}>
                <Link  to="/admin/bookingdetails">
                    <i className="soap-icon-businessbag circle" />
                    Booking Details
                </Link>
            </li>
            <li className={isActive === 'setting' ? 'active' : ''} onClick={() => handleClick('setting')}>
                <a data-toggle="tab" href="#settings">
                    <i className="soap-icon-settings circle" />
                    Settings
                </a>
            </li>
            {/* <li className={isActive === 'cardinfo' ? 'active' : ''} onClick={() => handleClick('cardinfo')}>
                <a data-toggle="tab" href="#cardinfo">
                    <i className="soap-icon-card" />
                    Card Info
                </a>
            </li>
            <li className={isActive === 'documentation' ? 'active' : ''} onClick={() => handleClick('documentation')}>
                <a data-toggle="tab" href="#documentation">
                    <i className="soap-icon-doc-plus" />
                    Documentation
                </a>
            </li> */}
        </ul>

    )
}
export default Sidebar;