import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FullPageLoader from '../../common/loader/FullPageLoader';
import Breadcrumb from '../layout/Breadcrumb';
import Sidebar from '../layout/Sidebar';
import FrontendHocComponent from '../hoc/FrontendHoc';

const Settings = () => {

  const [loader, showLoader, hideLoader] = FullPageLoader();

  const [updatePassword, setUpdatePassword] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
    error_list: [],
  })

  // Destructuring
  const { old_password, password, password_confirmation } = updatePassword;

  const handleInputChange = ({ target: { name, value } = {} }) => {

    setUpdatePassword({ ...updatePassword, [name]: value });
  }

  const handleChangePassword = (e) => {
    showLoader();
    const userId = localStorage.getItem('user_id');

    e.preventDefault();

    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post(`/api/change/password/${userId}`, updatePassword).then(res => {
        if (res.data.success === true) {
          hideLoader();
          Swal.fire("success", "User Password Sucessfully Updated", "success");
        }
        else {
          if (res.data.errors) {
            hideLoader();
            setUpdatePassword({ ...updatePassword, error_list: res.data.errors });
          } else {
            hideLoader();
            Swal.fire({
              title: "Error!",
              text: res.data.message,
              type: "Error",
              icon: 'error',
              confirmButtonText: 'Cool'
            });

          }
        }
      });
    });
  }

  return (
    <>
      {/* ========== breadcrum ==================  */}
      <Breadcrumb />
      <section id="content" className="gray-area">
        <div className="container">
          <div id="main" className="col-sm-12 col-md-12">
            <div className="tab-container full-width-style arrow-left dashboard">
              {/* ========== sidebar ==================  */}
              <Sidebar />
              <div className="tab-content">
                <div id="dashboard" className='tab-pane fade active in'>
                  <h2>Account Settings</h2>
                  <h5 className="skin-color">Change Your Password</h5>
                  <form onSubmit={handleChangePassword}>
                    <div className="row form-group">
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <label>Old Password</label>
                        <input type="text"
                          name='old_password'
                          value={old_password}
                          onChange={handleInputChange}
                          className="input-text full-width" />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <label>Enter New Password</label>
                        <input type="text"
                          name='password'
                          value={password}
                          onChange={handleInputChange}
                          className="input-text full-width" />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <label>Confirm New password</label>
                        <input
                          type="text"
                          name='password_confirmation'
                          value={password_confirmation}
                          onChange={handleInputChange}
                          className="input-text full-width" />
                      </div>
                    </div>
                    <div className="form-group">
                      <button type='submit' className="btn-medium">UPDATE PASSWORD</button>
                    </div>
                  </form>
                  <hr />
                 
                  {loader}
                </div>
              </div>
            </div>
            {loader}
          </div>
        </div>
      </section>
    </>
  )
}
export default FrontendHocComponent(Settings);