import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

// component
import FullPageLoader from "../../common/loader/FullPageLoader";
import Sidebar from "../layout/Sidebar";
import Breadcrumb from "../layout/Breadcrumb";

/**
 * Method to Edit the particular car details
 * @returns node
 */
const EditCar = () => {


  const { id } = useParams();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(true);
  const [carData, setCarData] = useState([]);

  const [error, setError] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [loader, showLoader, hideLoader] = FullPageLoader();

  const [image, setImage] = useState([]);

/**
 * Method to handle the input field changes
 * @param {object} e 
 */
  const handleOnChange = (e) => {
    e.persist();
    setCarData({ ...carData, [e.target.name]: e.target.value });
  }

  /**
* Method to handle the image change
* @param {object} e 
*/
const handleImage = (e) => {
  setImage({ image: e.target.files[0] });
};

  /**
   * Component Did Mount
   */
  useEffect(() => {
    axios.get(`api/car/details/${id}`).then(res => {
      if (res.data.result) {
        setCarData(res.data.result);
        setImage(res.data.result);
      } else if (res.data.success === false) {
        Swal.fire("warning", res.data.message, "error");
      }
      setLoading(false);
    });

  }, [id]);

  if (loading) {
    return <h4>Loading Edit User...</h4>
  }

  /**
   * Method to handle update car datas
   * @param {object} e 
   */
  const updateCarDatas = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    showLoader();

    const formData = new FormData();

    formData.append("user_id", carData.user_id);
    formData.append("car_info_id", carData.id);
    formData.append("car_image", image.image);
    formData.append("car_type", carData.car_type);
    formData.append("car_name", carData.car_name);
    formData.append("car_number", carData.car_number);
    formData.append("car_model_number", carData.car_model_number);
    formData.append("seats", carData.seats);
    formData.append("fuel_type", carData.fuel_type);
    formData.append("satellite_navigation", carData.satellite_navigation);
    formData.append("air_conditioning", carData.air_conditioning);
    formData.append("automatic_transmission", carData.automatic_transmission);
    formData.append("how_many_year_old", carData.how_many_year_old);
    formData.append("longitude", carData.longitude);
    formData.append("latitude", carData.latitude);
    formData.append("location", carData.location);
    formData.append("mileage", carData?.mileage);
    formData.append("status", carData.status);
    formData.append("additional_information", carData.additional_information);


    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/update/car/info', formData).then(res => {
        if (res.data.success) {
          hideLoader();
          navigate('/carInfo');
          Swal.fire("success", "Car Updation Successfully Done", "success");
          setError([]);
        }
        else {
          hideLoader();
          setError(res.data.errors);
        }
      });

    });
    setIsDisabled(false);
  }


  return (
    <>
      {/* ========== breadcrum ==================  */}
      <Breadcrumb />
      <section id="content" className="gray-area">
        <div className="container">
          <div id="main" className="col-sm-12 col-md-12">
            <div className="tab-container full-width-style arrow-left dashboard">
              {/* ========== sidebar ==================  */}
              <Sidebar />
              <div className="tab-content">
                <article className="image-box box innerstyle personal-details">
                  <div className="details">
                    <h2 className="box-title fullname">EDIT CAR </h2>
                  </div>
                </article>
                <div id="carInfo" className='tab-pane fade active in'>
                <form onSubmit={(e) => updateCarDatas(e)}>
                  <input type="hidden" name="user_id" value={carData.user_id} />
                  <div className="row">
                    <div className="col-sm-4 col-md-6">
                      <label>Car type</label>
                      <div className="selector">
                        <select
                          className=""
                          name="car_type"
                          value={carData.car_type}
                          onChange={handleOnChange}
                        >
                          <option value="">Economy</option>
                          <option value="Full Size">Full Size</option>
                          <option value="Luxury">Luxury</option>
                          <option value="Mini">Mini</option>
                          <option value="Intermediate">Intermediate</option>
                        </select>
                        <span className="custom-select form-control">{carData.car_type}</span>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>Car Name</label>
                      <input
                        type="text"
                        className="input-text form-control"
                        placeholder="Enter your car name number"
                        name="car_name"
                        value={carData?.car_name}
                        onChange={handleOnChange}
                      />
                      <span className="text-danger">
                        {error?.car_name}
                      </span>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <label>Car Number</label>
                      <input
                        type="text"
                        name="car_number"
                        className="input-text form-control"
                        placeholder="Enter your car number"
                        value={carData?.car_number}
                        onChange={handleOnChange}
                      />
                      <span className="text-danger">
                        {error?.car_number}
                      </span>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>Car Model Number</label>
                      <input
                        type="text"
                        name="car_model_number"
                        className="input-text form-control"
                        placeholder="Enter your car model number"
                        value={carData?.car_model_number}
                        onChange={handleOnChange}
                      />1
                      <span className="text-danger">
                        {error?.car_model_number}
                      </span>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <label>Seats</label>
                      <div className="selector">
                        <select
                          className="form-control"
                          name="seats"
                          value={carData?.seats}
                          onChange={handleOnChange}
                        >
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="7">7</option>
                          <option value="9">9</option>
                        </select>
                        <span className="custom-select form-control">{carData?.seats}</span>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>Fuel types</label>
                      <div className="selector">
                        <select
                          className="form-control"
                          name="fuel_type"
                          value={carData?.fuel_type}
                          onChange={handleOnChange}
                        >
                          <option value="">DISEL</option>
                          <option value="PETROL">PETROL</option>
                          <option value="ELECTRIC">ELECTRIC</option>
                        </select>
                        <span className="custom-select form-control">{carData?.fuel_type}</span>
                      </div>
                    </div>


                    <div className="col-sm-4 col-md-6">
                      <label>Car Mileage</label>
                      <input
                        type="text"
                        name="mileage"
                        className="input-text form-control"
                        placeholder="Enter your car Mileage"
                        value={carData?.mileage}
                        onChange={handleOnChange}
                      />
                      <span className="text-danger">
                        {error?.mileage}
                      </span>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>Location</label>
                      <input
                        type="text"
                        name="location"
                        className="input-text form-control"
                        placeholder="Enter Location"
                        value={carData?.location}
                        onChange={handleOnChange}
                      />
                      <span className="text-danger">
                        {error?.location}
                      </span>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>SATELLITE NAVIGATION</label>
                      <div className="selector">
                        <select
                          className="form-control"
                          name="satellite_navigation"
                          value={carData?.satellite_navigation}
                          onChange={handleOnChange}
                        >
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                        <span className="custom-select form-control">{carData?.satellite_navigation}</span>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-6">
                      <label>AIR CONDITIONING</label>
                      <div className="selector">
                        <select
                          className="form-control"
                          name="air_conditioning"
                          value={carData?.air_conditioning}
                          onChange={handleOnChange}
                        >
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                        <span className="custom-select form-control">{carData?.air_conditioning}</span>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <label>AUTOMATIC TRANSMISSION</label>
                      <div className="selector">
                        <select
                          className="form-control"
                          name="automatic_transmission"
                          value={carData?.automatic_transmission}
                          onChange={handleOnChange}
                        >
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                        <span className="custom-select form-control">{carData?.automatic_transmission}</span>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <label>Car Image</label>
                      <div className="selector">
                        <input
                          type="file"
                          name="image"
                          className="input-text form-control"
                          placeholder="Car Image"
                          onChange={handleImage}
                        />
                        <span className="text-danger">
                          {error?.car_image}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <label>HOW MANY YEAR OLD</label>
                      <div className="selector">
                        <input
                          type="text"
                          name="how_many_year_old"
                          className="input-text form-control"
                          placeholder="How many year old"
                          value={carData?.how_many_year_old}
                          onChange={handleOnChange}
                        />
                        <span className="text-danger">
                          {error?.how_many_year_old}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <label>ADDITIONAL INFORMATION</label>
                      <textarea
                        name="additional_information"
                        className="form-control"
                        rows={10}
                        placeholder="Please provide any additional information regarding your hotel requirements."
                        value={carData?.additional_information}
                        onChange={handleOnChange}
                      />
                      <span className="text-danger">
                        {error?.additional_information}
                      </span>
                    </div>
                    <div className="col-sm-4 col-md-6">
                      <div
                        className={`checkbox ${carData?.status === "1"
                          ? "checked"
                          : "!checked"
                          }`}
                      >
                        <label>
                          <input
                            type="checkbox"
                            name="status"
                            tabIndex={3}
                            value={
                              carData?.status === "1"
                                ? "0"
                                : "1"
                            }
                            onChange={handleOnChange}
                          />
                          Status
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 pull-right">
                      <button type="submit" className="button btn-large dark-blue1 btn-admin-panel">UPDATE</button>
                    </div>
                  </div>
                </form>
                {loader}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
            </>
  )
}
export default EditCar;