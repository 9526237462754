// npm packages
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import Slider from "./layout/Slider";
import AutoComplete from "../common/AutoComplete";
import { distance } from "../common/CalculateDistance";

const Home = () => {
  document.title = "RentalCars - Home";

  const navigate = useNavigate();
  const renderedSlider = useMemo(() => {
    return <Slider />
  }, []);
  let distanceInKm;

  // States 
  const [oneWayTripData, setOneWayTripData] = useState({
    country: 'India',
    state: "Utar Pradesh",
    district: "Ambedkarnagar",
    city: 'Akbarpur',
    local_market: 'Akbarpur',
    pick_up_place: '',
    drop_off_place: '',
    pick_up_date: '',
    pick_up_time: '',
    drop_off_date: '',
    drop_off_time: '',
    adults: '1',
    kids: '1',
    car_type: 'Economy',
  })

  const [roundTripData, setRoundTripData] = useState({
    country: 'India',
    state: "Utar Pradesh",
    district: "Ambedkarnagar",
    city: 'Akbarpur',
    local_market: 'Akbarpur',
    pick_up_place: '',
    drop_off_place: '',
    pick_up_date: '',
    pick_up_time: '',
    return_pick_up_date: '',
    return_pick_up_time: '',
    drop_off_date: '',
    drop_off_time: '',
    return_drop_off_date: '',
    return_drop_off_time: '',
    adults: '1',
    kids: '1',
    car_type: 'Economy',
  })

  const [isRoundTripData, setIsRoundTripData] = useState(false);
  let [pickupCoordinates, setPickupCoordinates] = useState();
  let [dropoffCoordinates, setDropoffCoordinates] = useState();

  /**
   * Method to handle the input field changes.
   * @param {object} param0
   */
  const handleInputChange = ({
    target: { name = "", value = "" || [] } = {},
  }) => {
    isRoundTripData ? setRoundTripData({ ...roundTripData, [name]: value })
      : setOneWayTripData({ ...oneWayTripData, [name]: value })
  };

  const pickupCordinationValue = (value, ll) => {
    isRoundTripData ? roundTripData.pick_up_place = value : oneWayTripData.pick_up_place = value;
    setPickupCoordinates(ll);
  }

  const dropoffCordinationValue = (value, ll) => {
    isRoundTripData ? roundTripData.drop_off_place = value : oneWayTripData.drop_off_place = value;
    setDropoffCoordinates(ll);
  }

  /**
   * Method to handlet the form submittion.
   * @param {object} e 
   */
  const onSubmit = (e) => {
    e.preventDefault();
    let stringifyData = isRoundTripData ? JSON.stringify(roundTripData) : JSON.stringify(oneWayTripData);
    localStorage.setItem('tripData', stringifyData);
    distanceInKm = distance(pickupCoordinates?.lat, pickupCoordinates?.lng, dropoffCoordinates?.lat,
      dropoffCoordinates?.lng);
    localStorage.setItem('distance', distanceInKm);
    navigate('/car');
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }




  return (
    <>
      {renderedSlider}
      <section id="content">
        <div className="search-box-wrapper">
          <div className="search-box container">
            <ul className="search-tabs clearfix">

              <li className="active">
                <a href="#cars-tab" data-toggle="tab">
                  ONE WAY
                </a>
              </li>
              <li>
                <a href="#cars-tab-round" data-toggle="tab" onClick={() => setIsRoundTripData(true)}>
                  ROUND TRIP
                </a>
              </li>

            </ul>
            <div className="visible-mobile">
              <ul id="mobile-search-tabs" className="search-tabs clearfix">

                <li className="active">
                  <a href="#cars-tab">ONE WAY</a>
                </li>
                <li>
                  <a href="#cars-tab-round" onClick={() => setIsRoundTripData(true)}>ROUND TRIP</a>
                </li>

              </ul>
            </div>
            <div className="search-tab-content">
              <div className="tab-pane fade active in" id="cars-tab">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-sm-6 col-md-2">
                      <label>Country</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="country"
                          value={oneWayTripData?.country}
                          onChange={handleInputChange}
                        >
                          <option value="India">India</option>
                        </select>
                        <span className="custom-select full-width">
                          {oneWayTripData?.country}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <label>State</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="state"
                          value={oneWayTripData?.state}
                          onChange={handleInputChange}
                        >
                          <option value='Uttar Pradesh'>Uttar Pardesh</option>
                        </select>
                        <span className="custom-select full-width">
                          {oneWayTripData?.state}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <label>District</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="district"
                          value={oneWayTripData?.district}
                          onChange={handleInputChange}
                        >
                          <option value="Ambedkarnagar">Ambedkarnagar</option>
                        </select>
                        <span className="custom-select full-width">
                          {oneWayTripData?.district}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>City</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="city"
                          value={oneWayTripData?.city}
                          onChange={handleInputChange}
                        >
                          <option value="Akbarpur">Akbarpur</option>
                          <option value="Tanda">Tanda</option>
                          <option value="Rajesultanpur">
                            Rajesultanpur
                          </option>
                          <option value="Jalalpur">Jalalpur</option>
                          <option value="Ashrafpur Kichhauchha">
                            Ashrafpur Kichhauchha
                          </option>
                          <option value="Iltifatganj">
                            Iltifatganj
                          </option>
                          <option value="jahangir Ganj">
                            Jahangir Ganj
                          </option>
                        </select>
                        <span className="custom-select full-width">
                          {oneWayTripData?.city}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>Local Market</label>
                      <div className="selector">
                        <div className="selector">
                          <select
                            className="full-width"
                            name="local_market"
                            value={oneWayTripData?.local_market}
                            onChange={handleInputChange}
                          >
                            <option value="Akbarpur">Akbarpur</option>
                            <option value="Baskhari">Baskhari</option>
                            <option value="Bhiti">Bhiti</option>
                            <option value="Bhiyaon">Bhiyaon</option>
                            <option value="Jahangir Ganj">Jahangir Ganj</option>
                            <option value="Jalalpur">Jalalpur</option>
                            <option value="Katehari">Katehari</option>
                            <option value="Ramnagar, Alapur">Ramnagar, Alapur</option>
                            <option value="Tanda">Tanda</option>
                            <option value="Rajesultanpur">Rajesultanpur</option>
                            <option value="Hanswar">Hanswar</option>
                          </select>
                          <span className="custom-select full-width">
                            {oneWayTripData?.local_market}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="title">Where</h4>
                      <div className="form-group">
                        <label>Pick Up</label>

                        <AutoComplete setCordinationValue={pickupCordinationValue} />
                      </div>
                      <div className="form-group">
                        <label>Drop Off</label>

                        <AutoComplete setCordinationValue={dropoffCordinationValue} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h4 className="title">When</h4>
                      <label>Pick-Up Date / Time</label>
                      <div className="row">
                        <div className="col-xs-6">
                          <div className="form-group">
                            <input
                              type="date"
                              name="pick_up_date"
                              className="input-text full-width"
                              value={oneWayTripData?.pick_up_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="form-group">
                            <input
                              type="time"
                              className="input-text full-width"
                              name="pick_up_time"
                              value={oneWayTripData?.pick_up_time}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Drop-Off Date / Time</label>
                        <div className="row">
                          <div className="col-xs-6">
                            {/* <div className="datepicker-wrap"> */}
                            <input
                              type="date"
                              name="drop_off_date"
                              className="input-text full-width"
                              value={oneWayTripData?.drop_off_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                            {/* </div> */}
                          </div>
                          <div className="col-xs-6">
                            <input
                              type="time"
                              className="input-text full-width"
                              name="drop_off_time"
                              value={oneWayTripData?.drop_off_time}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h4 className="title">Who</h4>
                      <div className="form-group row">
                        <div className="col-xs-3">
                          <label>Adults</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="adults"
                              value={oneWayTripData?.adults}
                              onChange={handleInputChange}
                            >
                              <option value={1}>01</option>
                              <option value={2}>02</option>
                              <option value={3}>03</option>
                              <option value={4}>04</option>
                            </select>
                            <span className="custom-select full-width">
                              {oneWayTripData?.adults}
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-3">
                          <label>Kids</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="kids"
                              value={oneWayTripData?.kids}
                              onChange={handleInputChange}
                            >
                              <option value={1}>01</option>
                              <option value={2}>02</option>
                              <option value={3}>03</option>
                              <option value={4}>04</option>
                            </select>
                            <span className="custom-select full-width">
                              {oneWayTripData?.kids}
                            </span>
                          </div>
                        </div>

                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label>Car Type</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="car_type"
                              value={oneWayTripData?.car_type}
                              onChange={handleInputChange}
                            >
                              <option disabled > -- select an option -- </option>
                              <option value="economy">Economy</option>
                              <option value="mini">Mini</option>
                              <option value="luxury">Luxury</option>
                              <option value="intermediate">Intermediate</option>
                              <option value="full size">Full Size</option>
                            </select>
                            <span className="custom-select full-width">
                              {oneWayTripData?.car_type}
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <label>&nbsp;</label>
                          <button type="submit" className="full-width icon-check">
                            SERACH NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* Round Trip  */}
              <div className="tab-pane fade" id="cars-tab-round">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-sm-6 col-md-2">
                      <label>Country</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="country"
                          value={roundTripData?.country}
                          onChange={handleInputChange}
                        >
                          <option value="India">India</option>
                        </select>
                        <span className="custom-select full-width">
                          {roundTripData?.country}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <label>State</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="state"
                          value={roundTripData?.state}
                          onChange={handleInputChange}
                        >
                          <option value='Uttar Pradesh'>Uttar Pardesh</option>
                        </select>
                        <span className="custom-select full-width">
                          {roundTripData?.state}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <label>District</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="district"
                          value={roundTripData?.district}
                          onChange={handleInputChange}
                        >
                          <option value="Ambedkarnagar">Ambedkarnagar</option>
                        </select>
                        <span className="custom-select full-width">
                          {roundTripData?.district}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>City</label>
                      <div className="selector">
                        <select
                          className="full-width"
                          name="city"
                          value={roundTripData?.city}
                          onChange={handleInputChange}
                        >
                          <option value="Akbarpur">Akbarpur</option>
                          <option value="Tanda">Tanda</option>
                          <option value="Rajesultanpur">
                            Rajesultanpur
                          </option>
                          <option value="Jalalpur">Jalalpur</option>
                          <option value="Ashrafpur Kichhauchha">
                            Ashrafpur Kichhauchha
                          </option>
                          <option value="Iltifatganj">
                            Iltifatganj
                          </option>
                          <option value="jahangir Ganj">
                            Jahangir Ganj
                          </option>
                        </select>
                        <span className="custom-select full-width">
                          {roundTripData?.city}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>Local Market</label>
                      <div className="selector">
                        <div className="selector">
                          <select
                            className="full-width"
                            name="local_market"
                            value={roundTripData?.local_market}
                            onChange={handleInputChange}
                          >
                            <option value="Akbarpur">Akbarpur</option>
                            <option value="Baskhari">Baskhari</option>
                            <option value="Bhiti">Bhiti</option>
                            <option value="Bhiyaon">Bhiyaon</option>
                            <option value="Jahangir Ganj">Jahangir Ganj</option>
                            <option value="Jalalpur">Jalalpur</option>
                            <option value="Katehari">Katehari</option>
                            <option value="Ramnagar, Alapur">Ramnagar, Alapur</option>
                            <option value="Tanda">Tanda</option>
                            <option value="Rajesultanpur">Rajesultanpur</option>
                            <option value="Hanswar">Hanswar</option>
                          </select>
                          <span className="custom-select full-width">
                            {roundTripData?.local_market}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <h4 className="title">Where</h4>
                      <div className="form-group">
                        <label>Pick Up</label>

                        <AutoComplete setCordinationValue={pickupCordinationValue} />
                      </div>
                      <div className="form-group">
                        <label>Drop Off</label>

                        <AutoComplete setCordinationValue={dropoffCordinationValue} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="title">When</h4>
                      <div className="form-group">
                        <label>Pick-Up Date / Time & Return Date / Time</label>
                        <div className="row">
                          <div className="col-xs-6 col-md-3">
                            {/* <div className="datepicker-wrap"> */}
                            <input
                              type="date"
                              name="pick_up_date"
                              className="input-text full-width"
                              value={roundTripData?.pick_up_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                            {/* </div> */}
                          </div>
                          <div className="col-xs-6 col-md-3">
                            <input
                              type="time"
                              className="input-text full-width"
                              name="pick_up_time"
                              value={roundTripData?.pick_up_time}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className="col-xs-6 col-md-3">
                            {/* <div className="datepicker-wrap"> */}
                            <input
                              type="date"
                              name="return_pick_up_date"
                              className="input-text full-width"
                              value={roundTripData?.return_pick_up_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                            {/* </div> */}
                          </div>
                          <div className="col-xs-6 col-md-3">
                              <input
                                type="time"
                                className="input-text full-width"
                                name="return_pick_up_time"
                                value={roundTripData?.return_pick_up_time}
                                onChange={handleInputChange}
                                required
                              />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Drop-Off Date / Timen & Return Date / Time</label>
                        <div className="row">
                          <div className="col-xs-6 col-md-3">
                            {/* <div className="datepicker-wrap"> */}
                            <input
                              type="date"
                              name="drop_off_date"
                              className="input-text full-width"
                              value={roundTripData?.drop_off_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                            {/* </div> */}
                          </div>
                          <div className="col-xs-6 col-md-3">
                              <input
                                type="time"
                                className="input-text full-width"
                                name="drop_off_time"
                                value={roundTripData?.drop_off_time}
                                onChange={handleInputChange}
                                required
                              />
                          </div>

                          <div className="col-xs-6 col-md-3">
                            {/* <div className="datepicker-wrap"> */}
                            <input
                              type="date"
                              name="return_drop_off_date"
                              className="input-text full-width"
                              value={roundTripData?.return_drop_off_date}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="mm/dd/yy"
                              required
                              min={getCurrentDate()}
                            />
                            {/* </div> */}
                          </div>
                          <div className="col-xs-6 col-md-3">
                              <input
                                type="time"
                                className="input-text full-width"
                                name="return_drop_off_time"
                                value={roundTripData?.return_drop_off_time}
                                onChange={handleInputChange}
                                required
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <h4 className="title">Who</h4>
                      <div className="form-group row">
                        <div className="col-xs-3">
                          <label>Adults</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="adults"
                              value={roundTripData?.adults}
                              onChange={handleInputChange}
                            >
                              <option value={1}>01</option>
                              <option value={2}>02</option>
                              <option value={3}>03</option>
                              <option value={4}>04</option>
                            </select>
                            <span className="custom-select full-width">
                              {roundTripData?.adults}
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-3">
                          <label>Kids</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="kids"
                              value={roundTripData?.kids}
                              onChange={handleInputChange}
                            >
                              <option value={1}>01</option>
                              <option value={2}>02</option>
                              <option value={3}>03</option>
                              <option value={4}>04</option>
                            </select>
                            <span className="custom-select full-width">
                              {roundTripData?.kids}
                            </span>
                          </div>
                        </div>

                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label>Car Type</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="car_type"
                              value={roundTripData?.car_type}
                              onChange={handleInputChange}
                            >
                              <option disabled > -- select an option -- </option>
                              <option value="economy">Economy</option>
                              <option value="mini">Mini</option>
                              <option value="luxury">Luxury</option>
                              <option value="intermediate">Intermediate</option>
                              <option value="full size">Full Size</option>
                            </select>
                            <span className="custom-select full-width">
                              {roundTripData?.car_type}
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <label>&nbsp;</label>
                          <button type="submit" className="full-width icon-check">
                            SERACH NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="global-map-area promo-box no-margin parallax"
          data-stellar-background-ratio="0.5"
        >
          <div className="container">
            <div className="table-wrapper hidden-table-sm">
              <div className="content-section description pull-right col-sm-9">
                <div className="table-wrapper hidden-table-sm">
                  <div className="table-cell">
                    <h2 className="m-title">
                      Tell us where you would like to go.
                      <br />
                      <em>1,000+ VIP Rental Cars Options!</em>
                    </h2>
                  </div>

                </div>
              </div>
              <div className="image-container col-sm-4">
                <img
                  width={292}
                  height={269}
                  alt=""
                  src={"images/cars/couple.png"}
                  className="animated"
                  data-animation-type="fadeInUp"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
