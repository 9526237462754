import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

export const ProtectedAdminRoutes = ({ children }) => {
    const admin = localStorage.getItem('user_role');
  if (!admin) {
    return (
      Swal.fire({
          icon: 'error',
          title: 'Sorry!!!',
          text: 'Only admin can access this!',
          confirmButtonColor: '#3090C7',
          footer: '<a href="/login">Click here to login first!</a>'
      }),
      <Navigate to="/" />
  )
  }
  return children;
};
