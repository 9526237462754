// NPM Packages
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import TableHeader from '../../data_table/header';
import SearchUser from '../../data_table/search';
import PaginationComponent from '../../data_table/pagination';
import AdminHocComponent from '../hoc/AdminHoc';
import FullPageLoader from '../../common/loader/FullPageLoader';

const CarList = () => {
    // States
    const [loading, setLoading] = useState(true);
    const [carList, setCarList] = useState([]);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 2;

    // destructuring
    const [loader, showLoader, hideLoader] = FullPageLoader();

    // header const
    const headers = [
        { name: "Sr. No.", field: "id", sortable: false },
        { name: "Car Name", field: "car_name", sortable: true },
        { name: "Car Type", field: "car_type", sortable: true },
        { name: "Car Mileage", field: "mileage", sortable: true },
        { name: "Image", field: "car_image", sortable: false },
        { name: "Status", field: "status", sortable: false },
        { name: "Action", field: "action", sortable: false }
    ]

    /**
     * Method to fetch users data from DB.
     */
    const fetchCarsList = () => {
        showLoader()
        axios.get(`/api/list/car/info`).then(res => {
            if (res.data.result) {
                hideLoader()
                setCarList(res.data.result);
            }
            setLoading(false);
        });
    }

    /**
     * Component Did Mount
     */
    useEffect(() => {
        fetchCarsList();
    }, []);


    /**
     * Method to handle the Search 
     */
    const CarsData = useMemo(() => {
        let computedcarList = carList;
        if (search) {
            computedcarList = computedcarList.filter(
                carList =>
                    carList.car_name?.toLowerCase().includes(search?.toLowerCase()) ||
                    carList.car_type?.toLowerCase().includes(search?.toLowerCase()) ||
                    carList.mileage?.toLowerCase().includes(search?.toLowerCase())
                //    carList.created_at?.toLowerCase().includes(search?.toLowerCase()) ||
                //    carList.phone_number?.toLowerCase().includes(search?.toLowerCase())
            );
        }
        setTotalItems(computedcarList.length);

        // sorting  user data
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedcarList = computedcarList.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])

            );
        }
        return computedcarList.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE

        );
    }, [carList, currentPage, search, sorting]);

    /**
     * Method to delete User 
     * @param {object} event 
     * @param {string} id 
     */
    const handleDeleteUser = (event, id) => {
        event.preventDefault();
        const thisclicked = event.currentTarget;
        axios.delete(`/api/car/info/delete/${id}`).then(res => {
            if (res.data.status_code === 200) {
                Swal.fire('success', res.data.message, 'success');
                thisclicked.closest('tr').remove();
            } else if (res.data.status_code === 404) {
                Swal.fire('error', 'No User Id Found', 'error');
            }
        });
    }

    /**
     * Method to handle active and inActive status.
     * @param {object} event 
     * @param {string} id 
     * @param {string} status 
     */
    const handleStatusUpdate = (event, id, status) => {
        event.preventDefault();
        const thisclicked = event.currentTarget;
        let statusMsg = status ? 'Inactive' : 'Active';
        thisclicked.innerText = statusMsg;
        axios.post(`/api/update/status/car/info/${id}/${status}`).then(res => {
            if (res.data.status_code === 200) {
                Swal.fire('success', res.data.message, 'success');
                axios.get(`/api/list/car/info`).then(res => {
                    if (res.data.result) {
                        setCarList(res.data.result);
                    }
                    setLoading(false);
                });
            } else if (res.data.status_code === 404) {
                Swal.fire('error', 'No Post Site Id Found', 'error');
            }
        });
    }


    var viewuser_HTMLTABLE = "";
    if (loading) {
        return <div>{loader}</div>;
    } else {
        viewuser_HTMLTABLE =
            CarsData.map((car, index) => {
                // const created_at = new Date(user.created_at);

                return (<tr key={car.id}>
                    <td>{index + 1}</td>
                    <td>{car.car_name}</td>
                    <td>{car.car_type}</td>
                    <td>{car.mileage}</td>
                    <td><img height="40px" src={car.car_image} /></td>
                    <td>

                        <button type="button" style={{ width: "62px" }} onClick={(event) => handleStatusUpdate(event, car.id, car.status)} className="btn btn-primary btn-circle btn-sm">
                            {car.status === '1' ? 'Active' : 'Inactive'}
                        </button>
                    </td>
                    <td className="list-action-sec">
                        <Link to={`/admin/car/view/${car.id}`} className="btn btn-info btn-circle btn-sm mr-2" title="View user details">
                            <i className="fa fa-eye"></i>
                        </Link>
                        <Link to={`/admin/car/edit/${car.id}`} className="btn btn-warning btn-circle btn-sm mr-2">
                            <i className="fas fa-edit"></i>
                        </Link>
                        <button type="submit" onClick={(event) => handleDeleteUser(event, car.id)} className="btn btn-danger btn-circle btn-sm">
                            <i className="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>)
            });
    }

    return (

        <div id="profile" className="tab-pane fade active in">



            <article className="image-box box innerstyle personal-details">
                <div className="details">
                    <Link to="/admin/car/post" className="button btn-mini pull-right edit-profile-btn">POST CAR</Link>
                    <h2 className="box-title fullname">CAR LIST</h2>
                </div>
            </article>
            <div className="filter-section clearfix">
                <div className="pull-right col-md-12 action">
                    <SearchUser
                        onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                        }}
                    />
                </div>
            </div>
            <div className="table-responsive list-search-section">
                <table className="table table-bordered" id="dataTable" width="100%">
                    <TableHeader headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })}
                    />
                    <tbody>

                        {viewuser_HTMLTABLE}

                    </tbody>
                </table>
            </div>
            <div className="view-profile">
                <div className="row">
                    <div className="col-sm-8 col-md-9">
                        <PaginationComponent
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>
            </div>


        </div>


    )
}
export default AdminHocComponent(CarList);