import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

export const ProtectedUserRoutes = ({ children }) => {
    const auth_token = localStorage.getItem('auth_token');
    const admin = localStorage.getItem('user_role');

    if (!auth_token && !admin ) {
        // user is not authenticated
        return (
            Swal.fire({
                icon: 'error',
                title: 'Sorry!!!',
                text: 'You are not logged In yet!',
                confirmButtonColor: '#3090C7',
                footer: '<a href="/login">Click here to login first!</a>'
            }),
            <Navigate to="/" />
        )
    }
    return children;
};
