// NPM Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation} from "react-router-dom";

// Components
import Breadcrumb from './layout/Breadcrumb';
import Filter from './layout/Filter';
import FullPageLoader from '../common/loader/FullPageLoader';

// Const
const showCarsPerPage = 2;

/**
 * Method to fetch the carsList.
 * @returns node
 */
const CarList = () => {

    document.title = "RentalCars - Car Listing";

    const {state} = useLocation();
    const tripdata = localStorage.getItem('tripData');
    const parsedTripData = JSON.parse(tripdata);

    // States
    const [carsList, setCarsList] = useState([]);
    const [next, setNext] = useState(showCarsPerPage);
    const [fareDetails, setFareDetails] = useState([]);

    const navgate = useNavigate();
    const dataCounts = carsList?.length;

    // destructred fare datas
    const { base_price, rate_per_km} = fareDetails[0] || [];
    const [loader, showLoader, hideLoader] = FullPageLoader();

    /**
     * Method to fetch cars list from DB.
     */
    const fetchCarsList = () => {
        showLoader();
        axios.get(`/api/list/car/info`).then(res => {
            if (res.data.result) {
                setCarsList(res.data.result);
            }
            hideLoader();
            // setLoading(false);
        });
       
    }

    const fetchFareDetails = () => { 
        axios.get(`/api/get/fare`).then(res => {
            if (res.data.result) {
                setFareDetails(res?.data?.result);
            }
        });
    }

    let kmPrice;
    let distance = localStorage.getItem('distance');
    /**
     * Method to manage the per km price according to car type
     */
    const manageKmPriceUsingCarType = (carType, vlu) => {

        switch (carType) {
            case 'Luxury':
                kmPrice = (600 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2);
              vlu && sessionStorage.setItem("kmPrice", (600 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2));
                break;
            case 'Mini':
                kmPrice = (500 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2);
                vlu && sessionStorage.setItem("kmPrice", (500 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2));
                break;
            case 'Full Size':
                kmPrice = (400 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2);
                vlu && sessionStorage.setItem("kmPrice", (400 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2));
                break;
            case 'Economy':
                kmPrice = (300 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2);
                vlu && sessionStorage.setItem("kmPrice", (300 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2));
                break;
            case 'Intermediate':
                kmPrice = (200 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2);
                vlu && sessionStorage.setItem("kmPrice", (200 + parseInt(base_price) + parseInt(rate_per_km) * distance).toFixed(2));
                break;
            default:
                break;
        }
    }

    /**
     * Component Did Mount
     */
    useEffect(() => {
        fetchCarsList();
        fetchFareDetails();
    }, []);


    /**
     * Method to handle the Load More 
     */
    const handelLoadMore = () => {
        setNext(next + showCarsPerPage);
    }

    const sortByName = () => {
        setCarsList(carsList?.sort((a, b) => a.car_name.localeCompare(b.car_name, 'en', { numeric: true })))
        navgate('/car');
    }
    const sortByPrice = () => {
        const carsWithKmPrice = carsList.map((car) => ({
            ...car,
            kmPrice: manageKmPriceUsingCarType(car.car_type),
        }));
        const sorted = carsWithKmPrice.sort((a, b) => a.kmPrice - b.kmPrice);
        setCarsList(sorted);
        navgate('/car');
    };
    

    return (
        <>
            {/* ========== breadcrum ==================  */}
            <Breadcrumb />

            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main">
                        <div className="row">

                            {/* ========== Filter ==================  */}
                            <Filter carsList={carsList} dataCounts={dataCounts} />

                            <div className="col-sm-8 col-md-9">
                                <div className="sort-by-section clearfix">
                                    <h4 className="sort-by-title block-sm">Sort results by:</h4>
                                    <ul className="sort-bar clearfix block-sm">
                                        <li id='name' className="active"><a onClick={sortByName} className="sort-by-container" href="#"><span>name</span></a></li>
                                        {/* <li id='price' className="active"><a onClick={sortByPrice} className="sort-by-container" href="#"><span>price</span></a></li> */}
                                        <li className="clearer visible-sms" />
                                    </ul>
                                    {/* <ul className="swap-tiles clearfix block-sm">
                                        <li className="swap-list active">
                                            <a href="#"><i className="soap-icon-list" /></a>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="car-list listing-style3 car">
                                    {
                                       (state?.length > 0 ? state : carsList)?.slice(0, next).map((list) => {
                                            const { id } = list;
                                            manageKmPriceUsingCarType(list?.car_type)
                                            return (
                                                <article className="box" key={list.id}>
                                                    <figure className="col-xs-3">
                                                        <span><img width={189} height={120} alt="rentalcar" src={list.car_image} /></span>
                                                    </figure>
                                                    <div className="details col-xs-9 clearfix">
                                                        <div className="col-sm-8">
                                                            <div className="clearfix">
                                                                <h4 className="box-title">{list.car_type}<small>{list.car_name}</small></h4>
                                                                <div className="logo">
                                                                    <img src={"/images/hotels/logo/3.png"} alt="rentalcar" />
                                                                </div>
                                                            </div>
                                                            <div className="amenities">
                                                                <ul>
                                                                    <li><i className="soap-icon-user circle" />{list.seats}</li>
                                                                    <li><i className="soap-icon-aircon circle" />{list.air_conditioning}</li>
                                                                    <li><i className="soap-icon-fmstereo circle" />{list.satellite_navigation}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-2 character">
                                                            <dl>
                                                                <dt className="skin-color">mileage</dt><dd>{list.mileage} miles</dd>
                                                                <dt className="skin-color">Pickup Time</dt><dd>{parsedTripData?.pick_up_time}</dd>
                                                                <dt className="skin-color">Location</dt><dd>{list.location}</dd>
                                                            </dl>
                                                        </div>
                                                        <div className="action col-xs-6 col-sm-2">
                                                            <span className="price"><small>per day</small>₹{kmPrice }</span>
                                                            <Link to={`/car-details/${id}`} 
                                                            className="button btn-small full-width"
                                                                onClick={() =>  manageKmPriceUsingCarType(list?.car_type, "select")}
                                                                >
                                                                select
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        })
                                    }
                                    {loader}
                                </div>
                              {carsList?.length > 2 && next  <= carsList?.length  &&  <button type='button' onClick={handelLoadMore} className="button uppercase full-width btn-large">load more listings</button> }
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>

        </>


    );
};
export default CarList;


