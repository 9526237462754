import React, { useState } from "react";
import LoaderImage from "./LoaderImage";

const FullPageLoader = () => {
    const [isLoading, setIsLoading] = useState(false);

    return [
        isLoading ? <LoaderImage /> : null,

        // Show Loader
        () => setIsLoading(true) , 

        // Hide Loader
        () => setIsLoading(false),
    ]
}
export default FullPageLoader;