// NPM Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
// Components
import FullPageLoader from '../common/loader/FullPageLoader';
import AdminHocComponent from './hoc/AdminHoc';

const BookingDetailsPage = () => {

    document.title = "RentalCars - Booking-Details";

    // use to get current route id
    const { bookingId } = useParams();

    // States
    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [bookingDetails, setBookingDetails] = useState([]);

    /**
     * Method to fetch car data targeting id from DB.
     */
    const fetchCarBookingDetails = () => {
        showLoader();
        axios.post(`/api/get/booking/detail`, { booking_id: bookingId }).then(res => {
            if (res.data.result) {
                hideLoader();
                setBookingDetails(res.data.result);
            }
        });
    }

    useEffect(() => {
        fetchCarBookingDetails();
    }, []);


    return (
        <>
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main" className="col-sm-12 col-md-12">
                        <div className="tab-container full-width-style arrow-left dashboard">
                            <div className="tab-content">
                                <div id="carInfo" className="tab-pane fade active in">
                                    <h2>Booking Details</h2>
                                    <div className="table">
                                        <table className="table table-bordered" width="100%">
                                            <tbody>
                                                <tr>
                                                    <th>Booking ID:</th>
                                                    <td>{bookingDetails.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>User ID:</th>
                                                    <td>{bookingDetails.user_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Car Info ID:</th>
                                                    <td>{bookingDetails.car_info_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Booking Days:</th>
                                                    <td>{bookingDetails.booking_days}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total Amount:</th>
                                                    <td>{bookingDetails.total_amount}</td>
                                                </tr>
                                                <tr>
                                                    <th>Pick Up Location:</th>
                                                    <td>{bookingDetails.pick_up_location}</td>
                                                </tr>
                                                <tr>
                                                    <th>Drop Off Location:</th>
                                                    <td>{bookingDetails.drop_off_location}</td>
                                                </tr>
                                                <tr>
                                                    <th>Pick Up Date:</th>
                                                    <td>{bookingDetails.pick_up_date}</td>
                                                </tr>
                                                <tr>
                                                    <th>Drop Off Date:</th>
                                                    <td>{bookingDetails.drop_off_date}</td>
                                                </tr>
                                                <tr>
                                                    <th>Pick Up Time:</th>
                                                    <td>{bookingDetails.pick_up_time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Drop Off Time:</th>
                                                    <td>{bookingDetails.drop_off_time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Adults:</th>
                                                    <td>{bookingDetails.adults}</td>
                                                </tr>
                                                <tr>
                                                    <th>Kids:</th>
                                                    <td>{bookingDetails.kids}</td>
                                                </tr>
                                                <tr>
                                                    <th>Latitude:</th>
                                                    <td>{bookingDetails.lat}</td>
                                                </tr>
                                                <tr>
                                                    <th>Longitude:</th>
                                                    <td>{bookingDetails.long}</td>
                                                </tr>
                                                <tr>
                                                    <th>Distance:</th>
                                                    <td>{bookingDetails.distance}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status:</th>
                                                    <td>{bookingDetails.status ? 'Active' : 'Inactive'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {loader}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}
export default AdminHocComponent(BookingDetailsPage);