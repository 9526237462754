// npm packages
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";

// components
import FullPageLoader from '../../common/loader/FullPageLoader';
import Sidebar from "../layout/Sidebar";
import Settings from "../auth/Settings";
import Breadcrumb from "../layout/Breadcrumb";
import FrontendHocComponent from "../hoc/FrontendHoc";

const FrontendDashboard = () => {
    const location = useLocation();

    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [userProfileData, setUserProfileData] = useState([]);

    const manageProfile = location.state ? location.state.page_name : '';

    const {
        email,
        first_name,
        last_name,
        phone_number,
        dob,
        street_address,
        city,
        zipcode,
        country,
        image,
    } = userProfileData;

    let userId = localStorage.getItem('user_id');


    useEffect(() => {
        const fetchUserData = () => {
            axios.get("/sanctum/csrf-cookie").then(() => {
                axios.get(`/api/get/profile/${userId}`).then((res) => {
                    setUserProfileData(res.data.result);
                });
            });
        };
        fetchUserData();
    }, []);

    return (
        <>
            {/* ========== breadcrum ==================  */}
            {/* <Breadcrumb /> */}
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main">
                        <div className="tab-container full-width-style arrow-left dashboard">
                            {/* ========== sidebar ==================  */}
                        
                            <div className="tab-content">
                                <div id="dashboard" className='tab-pane fade active in'>
                                
                                    <h1 className="no-margin skin-color">
                                        Hi {first_name}, Welcome to Rentalcar!
                                    </h1>
                                    <p>
                                        All your trips booked with us will appear here and you’ll be
                                        able to manage everything!
                                    </p>
                                    <br />
                                    <div className="row block">
                                        <div className="col-sm-6 col-md-3">
                                            <a href="/car">
                                                <div className="fact red">
                                                    <div className="numbers counters-box">
                                                        <dl>
                                                            <dt className="display-counter" data-value={3250}>
                                                                3250
                                                            </dt>
                                                            <dd>VIP Transports</dd>
                                                        </dl>
                                                        <i className="icon soap-icon-car" />
                                                    </div>
                                                    <div className="description">
                                                        <i className="icon soap-icon-longarrow-right" />
                                                        <span>View Cars</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="notification-area">
                                        <div className="info-box block">
                                            <span className="close" />
                                            <p>
                                                This is your Dashboard, the place to check your Profile,
                                                respond to Reservation Requests, view upcoming Trip
                                                Information, and much more.
                                            </p>
                                            <br />
                                            <ul className="circle">
                                                <li>
                                                    <span className="skin-color">Learn How It Works</span>{" "}
                                                    — Watch a short video that shows you how Travelo
                                                    works.
                                                </li>
                                                <li>
                                                    <span className="skin-color">Get Help</span> — View
                                                    our help section and FAQs to get started on Travelo.{" "}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="info-box block">
                                            <span className="close" />
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit. Necessitatibus ab quis a dolorem, placeat eos
                                                doloribus esse repellendus quasi libero illum dolore.
                                                Esse minima voluptas magni impedit, iusto, obcaecati
                                                dignissimos.
                                            </p>
                                        </div>
                                    </div> */}
                                    {/* <div className="row block">
                                        <div className="col-md-6 notifications">
                                            <h2>Notifications</h2>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-plane-right takeoff-effect yellow-bg" />
                                                    <span className="time pull-right">JUST NOW</span>
                                                    <p className="box-title">
                                                        London to Paris flight in{" "}
                                                        <span className="price">$120</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-hotel blue-bg" />
                                                    <span className="time pull-right">10 Mins ago</span>
                                                    <p className="box-title">
                                                        Hilton hotel &amp; resorts in{" "}
                                                        <span className="price">$247</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-car red-bg" />
                                                    <span className="time pull-right">39 Mins ago</span>
                                                    <p className="box-title">
                                                        Economy car for 2 days in{" "}
                                                        <span className="price">$39</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-cruise green-bg" />
                                                    <span className="time pull-right">1 hour ago</span>
                                                    <p className="box-title">
                                                        Baja Mexico 4 nights in{" "}
                                                        <span className="price">$537</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-hotel blue-bg" />
                                                    <span className="time pull-right">2 hours ago</span>
                                                    <p className="box-title">
                                                        Roosevelt hotel in{" "}
                                                        <span className="price">$170</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-hotel blue-bg" />
                                                    <span className="time pull-right">3 hours ago</span>
                                                    <p className="box-title">
                                                        Cleopatra Resort in{" "}
                                                        <span className="price">$247</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-car red-bg" />
                                                    <span className="time pull-right">3 hours ago</span>
                                                    <p className="box-title">
                                                        Elite Car per day in{" "}
                                                        <span className="price">$48.99</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="icon-box style1 fourty-space">
                                                    <i className="soap-icon-cruise green-bg" />
                                                    <span className="time pull-right">last night</span>
                                                    <p className="box-title">
                                                        Rome to Africa 1 week in{" "}
                                                        <span className="price">$875</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="load-more">
                                                    . . . . . . . . . . . . .{" "}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <h2>Recent Activity</h2>
                                            <div className="recent-activity">
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-plane-right circle takeoff-effect yellow-color" />
                                                            <span className="price">
                                                                <small>avg/person</small>$120
                                                            </span>
                                                            <h4 className="box-title">
                                                                Indianapolis to Paris
                                                                <small>Oneway flight</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-car circle red-color" />
                                                            <span className="price">
                                                                <small>per day</small>$45.39
                                                            </span>
                                                            <h4 className="box-title">
                                                                Economy Car<small>bmw mini</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-cruise circle green-color" />
                                                            <span className="price">
                                                                <small>from</small>$578
                                                            </span>
                                                            <h4 className="box-title">
                                                                Jacksonville to Asia<small>4 nights</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-hotel circle blue-color" />
                                                            <span className="price">
                                                                <small>Avg/night</small>$620
                                                            </span>
                                                            <h4 className="box-title">
                                                                Hilton Hotel &amp; Resorts
                                                                <small>Paris france</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-hotel circle blue-color" />
                                                            <span className="price">
                                                                <small>avg/night</small>$170
                                                            </span>
                                                            <h4 className="box-title">
                                                                Roosevelt Hotel<small>new york</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="icon soap-icon-plane-right circle takeoff-effect yellow-color" />
                                                            <span className="price">
                                                                <small>avg/person</small>$348
                                                            </span>
                                                            <h4 className="box-title">
                                                                Mexico to England<small>return flight</small>
                                                            </h4>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <a
                                                    href="#"
                                                    className="button green btn-small full-width"
                                                >
                                                    VIEW ALL ACTIVITIES
                                                </a>
                                            </div>
                                        </div>
                                    </div> 
                                    <hr />*/}
                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <h4>Benefits of Tavelo Account</h4>
                                            <ul className="benefits triangle hover">
                                                <li>
                                                    <a href="#">Faster bookings with lesser clicks</a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Track travel history &amp; manage bookings
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="#">
                                                        Manage profile &amp; personalize experience
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">Receive alerts &amp; recommendations</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 previous-bookings image-box style14">
                                            <h4>Your Previous Bookings</h4>
                                            <article className="box">
                                                <figure className="no-padding">
                                                    <a title="" href="#">
                                                        <img
                                                            alt=""
                                                            src="/images/dashboard/booking1.png"
                                                            width={63}
                                                            height={59}
                                                        />
                                                    </a>
                                                </figure>
                                                <div className="details">
                                                    <h5 className="box-title">
                                                        <a href="#">Half-Day Island Tour</a>
                                                        <small className="fourty-space">
                                                            <span className="price">$35</span> Family Package
                                                        </small>
                                                    </h5>
                                                </div>
                                            </article>
                                            <article className="box">
                                                <figure className="no-padding">
                                                    <a title="" href="#">
                                                        <img
                                                            alt=""
                                                            src="/images/dashboard/booking2.png"
                                                            width={63}
                                                            height={59}
                                                        />
                                                    </a>
                                                </figure>
                                                <div className="details">
                                                    <h5 className="box-title">
                                                        <a href="#">Ocean Park Tour</a>
                                                        <small className="fourty-space">
                                                            <span className="price">$26</span> Per Person
                                                        </small>
                                                    </h5>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-md-4">
                                            <h4>Need Travelo Help?</h4>
                                            <div className="contact-box">
                                                <p>
                                                    We would be more than happy to help you. Our team
                                                    advisor are 24/7 at your service to help you.
                                                </p>
                                                <address className="contact-details">
                                                    <span className="contact-phone">
                                                        <i className="soap-icon-phone" /> 1-800-123-HELLO
                                                    </span>
                                                    <br />
                                                    <a className="contact-email" href="#">
                                                        help@travelo.com
                                                    </a>
                                                </address>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {loader}
        </>

    )
}
export default FrontendHocComponent(FrontendDashboard);