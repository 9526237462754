import React, { useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

import Footer from "./containers/frontend/layout/Footer";
import Home from "./containers/frontend/Home";
import Login from "./containers/frontend/auth/Login";
import Header from "./containers/frontend/layout/Header";
import { PostCarInfo } from "./containers/frontend/PostCarInfo";
import Register from "./containers/frontend/auth/Register";
import Settings from "./containers/frontend/auth/Settings";
import ForgetPassword from "./containers/frontend/auth/ForgetPassword";
import EditUserProfile from "./containers/frontend/auth/EditUserProfile";
import CarList from "./containers/frontend/CarList";
import CarDetails from "./containers/frontend/CarDetails";
import Booking from "./containers/frontend/Booking";
import ThankYou from "./containers/frontend/ThankYou";

import AdminFooter from "./containers/backend/layout/Footer";
import AdminHeader from "./containers/backend/layout/Header";
import AdminSidebar from "./containers/backend/layout/Sidebar";

import AdminDashboard from "./containers/backend/dashboard/AdminDashboard";
import AdminUserList from "./containers/backend/user/UserList";
import AdminViewUser from "./containers/backend/user/ViewUser";

import Filter from "./containers/frontend/layout/Filter";
import Dashboard from "./containers/frontend/Dashboard";
import FrontendDashboard from "./containers/frontend/dashboard/FrontendDashboard";


import Page404 from "./containers/errors/Page404";
import AdminEditUser from "./containers/backend/user/EditUser";
import AdminAddUser from "./containers/backend/user/AddUser";
import ViewProfile from "./containers/frontend/auth/ViewProfile";
import AdminCarList from "./containers/backend/car/CarList";
import AdminEditCar from "./containers/backend/car/EditCar";
import AdminViewCar from "./containers/backend/car/ViewCar";
import AdminPostCar from "./containers/backend/car/PostCar";
import AdminCarFare from "./containers/backend/car/Fare";
import AdminLayout from "./containers/backend/layout/AdminLayout";

import FrontendCarList from "./containers/frontend/car/CarList";
import FrontendPostCar from "./containers/frontend/car/PostCar";
import FrontendEditCar from "./containers/frontend/car/EditCar";
import FrontendViewCar from "./containers/frontend/car/ViewCar";
import Document from "./containers/frontend/auth/Document";
import FrontendLayout from "./containers/frontend/layout/FrontendLayout";
import { ProtectedAdminRoutes } from "./containers/authenticated_routes/ProtectedAdminRoutes";
import { ProtectedUserRoutes } from "./containers/authenticated_routes/ProtectedUserRoutes";
import ComponentLoader from "./containers/common/loader/ComponentLoader";
import ViewBookingDetails from "./containers/backend/ViewBookingDetails";
import FrontendViewBookingDetails from "./containers/frontend/ViewBookingDetails";
import FrontendBookingDetailsPage from "./containers/frontend/BookingDetailsPage";
import BackendBookingDetailsPage from "./containers/backend/BookingDetailsPage";

//axios.defaults.baseURL = "http://127.0.0.1:8000";
axios.defaults.baseURL = "https://bigwheel.co.in/api/";
axios.defaults.headers.post["Accept"] = "applic0ation/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('auth_token');
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

function App() {

	return (
		<>
			<div className="App">
				<Router>
					<Routes>
						{/* Frontend Routes */}
						<Route element={<FrontendLayout />}>
							<Route path="/" exact element={<Home />} />
							<Route path="/login" exact element={<Login />} />
							<Route path="/postcarinfo" exact element={<PostCarInfo />} />
							<Route path="/register" exact element={<Register />} />
							<Route path="/settings" exact element={<Settings />} />
							<Route path="/forgetpswrd" exact element={<ForgetPassword />} />
							<Route path="/edituser/:userId" exact element={<EditUserProfile />} />
							<Route path="/car" exact element={<CarList />} />
							<Route path="/car-details/:id" exact element={<CarDetails />} />
							<Route path="/car-booking-details/:bookingId" exact element={<FrontendBookingDetailsPage />} />
							<Route path="/booking" exact element={<Booking />} />
							<Route path="/thankyou" exact element={<ThankYou />} />

							<Route path="/dashboard" exact element={<ProtectedUserRoutes><Dashboard tab={'dashboard'} /></ProtectedUserRoutes>} />
							<Route path="/profile" exact element={<ViewProfile tab={'profile'} />} />
							<Route path="/setting" exact element={<Settings tab={'setting'} />} />
							<Route path="/documentation" exact element={<Document tab={'document'} />} />
							<Route path="/bookingdetails" exact element={<FrontendViewBookingDetails />} />

							{/* Frontend Dashboard Cars Routes */}
							<Route path="/carInfo" exact element={<FrontendCarList />} />
							<Route path="/car/post" exact element={<FrontendPostCar />} />
							<Route path="/car/view/:id" exact element={<FrontendViewCar />} />
							<Route path="/car/edit/:id" exact element={<FrontendEditCar />} />


							<Route path="*" exact element={<Page404 />} />
							<Route path="/login" exact element={<Login />} />
						</Route>

						{/* Backend Admin Routes */}
						<Route element={<AdminLayout />}>
							<Route path='/admin/dashboard' exact element={<ProtectedAdminRoutes><AdminDashboard /></ProtectedAdminRoutes>} />
							<Route path="/admin/bookingdetails" exact element={<ViewBookingDetails />} />
							<Route path="/admin/car-booking-details/:bookingId" exact element={<BackendBookingDetailsPage />} />

							{/* Backend Users Routes */}
							<Route path="/admin/user/index" exact element={<AdminUserList />} />
							<Route path="/admin/user/add" exact element={<AdminAddUser />} />
							<Route path="/admin/user/view/:id" exact element={<AdminViewUser />} />
							<Route path="/admin/user/edit/:id" exact element={<AdminEditUser />} />

							{/* Backend Cars Routes */}
							<Route path="/admin/car/index" exact element={<AdminCarList />} />
							<Route path="/admin/car/post" exact element={<AdminPostCar />} />
							<Route path="/admin/car/view/:id" exact element={<AdminViewCar />} />
							<Route path="/admin/car/edit/:id" exact element={<AdminEditCar />} />
							<Route path="/admin/car/fare" exact element={<AdminCarFare />} />
						</Route>

					</Routes>
				</Router>
			</div>
		</>
	);
}

export default App;
