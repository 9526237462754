import React, { useEffect, useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import FullPageLoader from '../../common/loader/FullPageLoader';
import { Link, useLocation } from "react-router-dom";
import AdminHocComponent from "../hoc/AdminHoc";


const AdminDashboard = () => {
    const location = useLocation();

    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [userProfileData, setUserProfileData] = useState([]);

    const manageProfile = location.state ? location.state.page_name : '';

    const {
        email,
       first_name,
        last_name,
        phone_number,
        dob,
        street_address,
        city,
        zipcode,
        country,
        image,
     } = userProfileData;

    let userId = localStorage.getItem('user_id');

    useEffect(() => {
        const fetchUserData = () => {
            axios.get("/sanctum/csrf-cookie").then(() => {
                axios.get(`/api/get/profile/${userId}`).then((res) => {
                    setUserProfileData(res.data.result);
                    localStorage.setItem("loggedInUser", res.data.result.first_name)
                });
            });
        };
        fetchUserData();
    }, []);

    return (
        <div>
            <div id="dashboard" className='tab-pane fade active in'>
                <h1 className="no-margin skin-color">
                    Hi {first_name}, Welcome to Rentalcar!
                </h1>
                <p>
                    All your trips booked with us will appear here and you’ll be
                    able to manage everything!
                </p>
                <br />
                <div className="row block">

                    <div className="col-sm-6 col-md-3">
                        <a href="/car">
                            <div className="fact red">
                                <div className="numbers counters-box">
                                    <dl>
                                        <dt className="display-counter" data-value={3250}>
                                            3250
                                        </dt>
                                        <dd>VIP Transports</dd>
                                    </dl>
                                    <i className="icon soap-icon-car" />
                                </div>
                                <div className="description">
                                    <i className="icon soap-icon-longarrow-right" />
                                    <span>View Cars</span>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            

            </div>
            {loader}
        </div>
    )
}
export default AdminDashboard