// NPM Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
// Components
import FullPageLoader from "../../common/loader/FullPageLoader";
import Breadcrumb from "../layout/Breadcrumb";
import Sidebar from "../layout/Sidebar";

const ViewCar = () => {

    document.title = "RentalCars - ViewCar";

    // use to get current route id
    const { id } = useParams();

    // States
    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [carDatas, setCarDatas] = useState([]);
    // const [carsList, setCarsList] = useState([]);

    /**
     * Method to fetch car data targeting id from DB.
     */
    const fetchCarDatas = () => {
        showLoader();
        axios.get(`/api/car/details/${id}`).then(res => {
            if (res.data.result) {
                hideLoader();
                setCarDatas(res.data.result);
            }
        });
    }

    // Destructring the api response datas
    const {
        additional_information,
        air_conditioning,
        automatic_transmission,
        car_image,
        car_model_number,
        car_name,
        car_number,
        car_type,
        created_at,
        fuel_type,
        how_many_year_old,
        location,
        mileage,
        satellite_navigation,
        seats,
        status,

    } = carDatas

    useEffect(() => {
        fetchCarDatas();
    }, []);


    return (
        <>
            {/* ========== breadcrum ==================  */}
            <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main" className="col-sm-12 col-md-12">
                        <div className="tab-container full-width-style arrow-left dashboard">
                            {/* ========== sidebar ==================  */}
                            <Sidebar />
                            <div className="tab-content">
                                <article className="image-box box innerstyle personal-details">
                                    <div className="details">
                                        <h2 className="box-title fullname">CAR DETAILS</h2>
                                    </div>
                                </article>

                                <div id="carInfo" className="tab-pane fade active in">
                                    <div className="featured-image box">
                                        <img className="responsive" src={car_image} alt="rentalcar" />
                                    </div>
                                    <div className="table">
                                        <table className=" table table-bordered" width="100%">

                                            <tbody>
                                                <tr>
                                                    <th className='col-md-4'>Car Name:</th>
                                                    <td >{car_name}</td>
                                                </tr>
                                                <tr>
                                                    <th >Car Type:</th>
                                                    <td>{car_type}</td>
                                                </tr>
                                                <tr>
                                                    <th >Car Number:</th>
                                                    <td>{car_number}</td>
                                                </tr>
                                                <tr>
                                                    <th >Fuel Type:</th>
                                                    <td>{fuel_type}</td>
                                                </tr>
                                                <tr>
                                                    <th >Car Model Number:</th>
                                                    <td>{car_model_number}</td>
                                                </tr>
                                                <tr>
                                                    <th >Seats:</th>
                                                    <td>{seats}</td>
                                                </tr>
                                                <tr>
                                                    <th >Satellite Navigation:</th>
                                                    <td>{satellite_navigation}</td>
                                                </tr>
                                                <tr>
                                                    <th >How Many Year Old:</th>
                                                    <td>{how_many_year_old}</td>
                                                </tr>
                                                <tr>
                                                    <th >Mileage:</th>
                                                    <td>{mileage}</td>
                                                </tr>
                                                <tr>
                                                    <th >Air Conditioning:</th>
                                                    <td>{air_conditioning}</td>
                                                </tr>
                                                <tr>
                                                    <th >Automatic Transmission:</th>
                                                    <td>{automatic_transmission}</td>
                                                </tr>
                                                <tr>
                                                    <th >Created At:</th>
                                                    <td>{created_at}</td>
                                                </tr>

                                                <tr>
                                                    <th>Status:</th>
                                                    <td className="center">
                                                        {status ? 'Active' : 'Inactive'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th >Additional Information:</th>
                                                    <td>{additional_information}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {loader}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    );
}
export default ViewCar;