// npm packages
import React from "react";
import { Outlet } from "react-router-dom";

// components
import Header from "./Header";
import Footer from "./Footer";

const FrontendLayout = () => (
	<>
		<div id="page-wrapper">
			<Header />
			<Outlet />
			<Footer />
		</div>

	</>
);
export default FrontendLayout;