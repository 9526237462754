import React from 'react';
import { Link } from "react-router-dom";


const Breadcrumb = () => {
    
    return (
        <div className="page-title-container">
            <div className="container">
                <div className="page-title pull-left">
                    <h2 className="entry-title">Rentalcar Search Results</h2>
                </div>
                <ul className="breadcrumbs pull-right">
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/carInfo">CAR LIST</Link></li>

                </ul>
            </div>
        </div>

    );
};
export default Breadcrumb;


