// import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

// const Slider = () => {
//   return (

//     <>

//     <div id="slideshow">
//       <div className="fullwidthbanner-container caption-nowrap">
//         <div className="revolution-slider" style={{ height: 0, overflow: 'hidden' }}>
//           <ul>    {/* SLIDE  */}
//             <li data-index="rs-7" data-transition="slidedown" data-slotamount={7} data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={800} data-thumb="images/revslider/bg7.jpg" data-rotate={0} data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
//               {/* MAIN IMAGE */}
//               <img src="images/revslider/bg7.jpg" alt="rentalapp" title="Slider1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
//               {/* LAYERS */}
//               {/* LAYER NR. 1 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-1" data-x={638} data-y={43} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={800} data-responsive_offset="on" style={{ zIndex: 5 }}><img src="images/revslider/ba231.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 2 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-2" data-x={419} data-y={40} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:top;s:1500;e:Power3.easeInOut;" data-transform_out="y:-50px;opacity:0;s:300;s:300;" data-start={1100} data-responsive_offset="on" style={{ zIndex: 6 }}><img src="images/revslider/ba221.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 3 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-3" data-x={486} data-y={-34} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={1400} data-responsive_offset="on" style={{ zIndex: 7 }}><img src="images/revslider/sun.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 4 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-4" data-x={236} data-y={305} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={5600} data-responsive_offset="on" style={{ zIndex: 8 }}><img src="images/revslider/islandshadow.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 5 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-5" data-x={242} data-y={264} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={6200} data-responsive_offset="on" style={{ zIndex: 9 }}><img src="images/revslider/island1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 6 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-6" data-x={373} data-y={231} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="auto:auto;s:300;" data-start={6500} data-responsive_offset="on" style={{ zIndex: 10 }}><img src="images/revslider/chairs.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 7 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-7" data-x={72} data-y="bottom" data-voffset={60} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:right;s:300;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={5900} data-responsive_offset="on" style={{ zIndex: 11 }}><img src="images/revslider/holiday_1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 8 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-8" data-x={459} data-y={40} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:1000;y:360;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={1700} data-responsive_offset="on" style={{ zIndex: 12 }}><img src="images/revslider/plans.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 9 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-9" data-x={1059} data-y={305} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={2600} data-responsive_offset="on" style={{ zIndex: 13 }}><img src="images/revslider/cloud4.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 10 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-10" data-x={15} data-y="center" data-voffset={20} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:bottom;s:1500;e:Power3.easeInOut;" data-transform_out="y:50px;opacity:0;s:300;s:300;" data-start={3000} data-responsive_offset="on" style={{ zIndex: 14 }}><img src="images/revslider/bg3434.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 11 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-11" data-x={1149} data-y={366} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={4100} data-responsive_offset="on" style={{ zIndex: 15 }}><img src="images/revslider/ballon2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 12 */}
//               <div className="tp-caption large_bold_white_med1221   tp-resizeme" id="slide-7-layer-12" data-x={53} data-y={151} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4400} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 16 }}>It’s the right time to<br />take your family to<br />a great place! </div>
//               {/* LAYER NR. 13 */}
//               <div className="tp-caption large_bold_black2_2   tp-resizeme" id="slide-7-layer-13" data-x={77} data-y={291} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4700} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 17 }}>Book now and get upto <strong>50%</strong>off on<br />all the flights to Europe. </div>
//               {/* LAYER NR. 14 */}
//               <a href="#" className="tp-caption largewhitebg_button1_yellow   tp-resizeme" id="slide-7-layer-14" data-x={73} data-y={370} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={5000} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 18 }}>BOOK NOW</a>
//               {/* LAYER NR. 15 */}
//               <a href="#" className="tp-caption largewhitebg_button1_yellow   tp-resizeme" id="slide-7-layer-15" data-x={240} data-y={370} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={5300} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 19 }}>MORE INFO</a>
//               {/* LAYER NR. 16 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-16" data-x={1025} data-y={-16} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:right;s:1500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={6800} data-responsive_offset="on" style={{ zIndex: 20 }}><img src="images/revslider/leaf2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 17 */}
//               <div className="tp-caption   tp-resizeme" id="slide-7-layer-17" data-x="right" data-hoffset data-y="bottom" data-voffset data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:bottom;s:1500;e:Power3.easeInOut;" data-transform_out="y:50px;opacity:0;s:300;s:300;" data-start={7100} data-responsive_offset="on" style={{ zIndex: 21, marginLeft: "500px" }}><img src="images/revslider/family2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//             </li>
//             {/* SLIDE  */}
//             <li data-index="rs-8" data-transition="slideup" data-slotamount={7} data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={800} data-thumb="images/revslider/sky.jpg" data-rotate={0} data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
//               {/* MAIN IMAGE */}
//               <img src="images/revslider/sky.jpg" alt="rentalapp" title="Slider1" data-bgposition="center top" data-bgfit="normal" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
//               {/* LAYERS */}
//               {/* LAYER NR. 1 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-1" data-x data-y data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={1700} data-responsive_offset="on" style={{ zIndex: 5 }}><img src="images/revslider/clouds1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 2 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-2" data-x="right" data-hoffset={-400} data-y={30} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:300;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={2000} data-responsive_offset="on" style={{ zIndex: 6 }}><img src="images/revslider/cloud2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 3 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-3" data-x="right" data-hoffset={141} data-y={137} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:1000;y:360;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={2300} data-responsive_offset="on" style={{ zIndex: 7 }}><img src="images/revslider/plane1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 4 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-4" data-x="center" data-hoffset data-y="bottom" data-voffset={120} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:50px;opacity:0;s:2000;e:Power3.easeInOut;" data-transform_out="y:50px;opacity:0;s:300;s:300;" data-start={1400} data-responsive_offset="on" style={{ zIndex: 8 }}><img src="images/revslider/building1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 5 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-5" data-x="center" data-hoffset data-y="bottom" data-voffset={108} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1.875;e:Linear.easeNone;" data-transform_out="opacity:0;s:600;s:600;" data-start={0} data-responsive_offset="on" style={{ zIndex: 9 }}><img src="images/revslider/grass12.png" alt="rentalapp" data-ww="2080px" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 6 */}
//               {/* <div className="tp-caption   tp-resizeme" id="slide-8-layer-6" data-x="center" data-hoffset data-y="bottom" data-voffset data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1.875;e:Linear.easeNone;" data-transform_out="opacity:0;s:600;s:600;" data-start={0} data-responsive_offset="on" style={{zIndex: 10}}><img src="images/revslider/road.jpg" alt="rentalapp" data-ww="auto" data-hh="107px" data-no-retina /> </div> */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-6" data-x="center" data-hoffset data-y="bottom" data-voffset data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1.875;e:Linear.easeNone;" data-transform_out="opacity:0;s:600;s:600;" data-start={0} data-responsive_offset="on" style={{ zIndex: 10 }}> </div>

//               {/* LAYER NR. 7 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-7" data-x="center" data-hoffset data-y="bottom" data-voffset={96} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1.875;e:Linear.easeNone;" data-transform_out="opacity:0;s:600;s:600;" data-start={0} data-responsive_offset="on" style={{ zIndex: 11 }}><img src="images/revslider/tile.png" alt="rentalapp" data-ww="2084px" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 8 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-8" data-x="center" data-hoffset data-y="bottom" data-voffset={10} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:left;s:2500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={2600} data-responsive_offset="on" style={{ zIndex: 12 }}><img src="images/revslider/car1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 9 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-9" data-x="right" data-hoffset={-50} data-y="bottom" data-voffset={10} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={2900} data-responsive_offset="on" style={{ zIndex: 13 }}><img src="images/revslider/family1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 10 */}
//               <div className="tp-caption   tp-resizeme" id="slide-8-layer-10" data-x={15} data-y="bottom" data-voffset={20} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={3200} data-responsive_offset="on" style={{ zIndex: 14 }}><img src="images/revslider/backgrounnd-blue1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 11 */}
//               <div className="tp-caption large_bold_white_med1221   tp-resizeme" id="slide-8-layer-11" data-x={55} data-y={153} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="y:50px;opacity:0;s:300;s:300;" data-start={3500} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 15 }}>It’s the right time to<br />take your family to<br />a great place! </div>
//               {/* LAYER NR. 12 */}
//               <div className="tp-caption large_bold_black2_2   tp-resizeme" id="slide-8-layer-12" data-x={75} data-y={302} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:300;e:Power3.easeInOut;" data-transform_out="y:50px;opacity:0;s:300;s:300;" data-start={3800} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 16 }}>Book now and get upto <strong>50%</strong>off on<br />all the flights to Europe. </div>
//               {/* LAYER NR. 13 */}
//               <a href="#" className="tp-caption largewhitebg_button1_blue   tp-resizeme" id="slide-8-layer-13" data-x={65} data-y={410} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4100} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 17 }}>BOOK NOW</a>
//               {/* LAYER NR. 14 */}
//               <a href="#" className="tp-caption largewhitebg_button1_blue   tp-resizeme" id="slide-8-layer-14" data-x={229} data-y={410} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:right;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4400} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 18 }}>MORE INFO </a>
//             </li>
//             {/* SLIDE  */}
//             <li data-index="rs-9" data-transition="fade" data-slotamount={7} data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={800} data-thumb="images/revslider/sky1.jpg" data-rotate={0} data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
//               {/* MAIN IMAGE */}
//               <img src="images/revslider/sky1.jpg" alt="rentalapp" title="Slider1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
//               {/* LAYERS */}
//               {/* LAYER NR. 1 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-1" data-x={-422} data-y={464} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={500} data-responsive_offset="on" style={{ zIndex: 5 }}><img src="images/revslider/grass2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 2 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-2" data-x={435} data-y={42} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={800} data-responsive_offset="on" style={{ zIndex: 6 }}><img src="images/revslider/ba231.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 3 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-3" data-x={251} data-y={36} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:top;s:1500;e:Power3.easeInOut;" data-transform_out="y:-50px;opacity:0;s:300;s:300;" data-start={1100} data-responsive_offset="on" style={{ zIndex: 7 }}><img src="images/revslider/ba221.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 4 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-4" data-x={294} data-y={-32} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={1400} data-responsive_offset="on" style={{ zIndex: 8 }}><img src="images/revslider/sun.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 5 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-5" data-x={296} data-y={64} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:1000;y:360;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={1700} data-responsive_offset="on" style={{ zIndex: 9 }}><img src="images/revslider/plans.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 6 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-6" data-x={-289} data-y={217} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={2000} data-responsive_offset="on" style={{ zIndex: 10 }}><img src="images/revslider/new_fan.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 7 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-7" data-x={61} data-y={329} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={2300} data-responsive_offset="on" style={{ zIndex: 11 }}><img src="images/revslider/fan_2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 8 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-8" data-x={1059} data-y={305} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={2600} data-responsive_offset="on" style={{ zIndex: 12 }}><img src="images/revslider/cloud4.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 9 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-9" data-x="right" data-hoffset={-500} data-y data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:50px;opacity:0;s:300;s:300;" data-start={2900} data-responsive_offset="on" style={{ zIndex: 13 }}><img src="images/revslider/leafs1.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 10 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-10" data-x="right" data-hoffset={-100} data-y="bottom" data-voffset={200} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;s:300;" data-start={3200} data-responsive_offset="on" style={{ zIndex: 14 }}><img src="images/revslider/ballon2.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 11 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-11" data-x={433} data-y={179} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:bottom;s:2500;e:Power3.easeInOut;" data-transform_out="y:bottom;s:300;s:300;" data-start={3500} data-responsive_offset="on" style={{ zIndex: 15 }}><img src="images/revslider/family.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 12 */}
//               <div className="tp-caption   tp-resizeme" id="slide-9-layer-12" data-x={15} data-y={113} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="y:top;s:1500;e:Power3.easeInOut;" data-transform_out="y:bottom;s:300;s:300;" data-start={3800} data-responsive_offset="on" style={{ zIndex: 16 }}><img src="images/revslider/background.png" alt="rentalapp" data-ww="auto" data-hh="auto" data-no-retina /> </div>
//               {/* LAYER NR. 13 */}
//               <div className="tp-caption large_bold_white_med1221   tp-resizeme" id="slide-9-layer-13" data-x={55} data-y={153} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:300;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4100} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 17 }}>It’s the right time to<br />take your family to<br />a great place! </div>
//               {/* LAYER NR. 14 */}
//               <div className="tp-caption large_bold_black2_2   tp-resizeme" id="slide-9-layer-14" data-x={75} data-y={302} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:300;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4400} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 18 }}>Book now and get upto <strong>50%</strong>off on<br />all the flights to Europe. </div>
//               {/* LAYER NR. 15 */}
//               <a href="#" className="tp-caption largewhitebg_button1_green   tp-resizeme" id="slide-9-layer-15" data-x={65} data-y={410} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:left;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={4700} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 19 }}>BOOK NOW</a>
//               {/* LAYER NR. 16 */}
//               <a href="#" className="tp-caption largewhitebg_button1_green   tp-resizeme" id="slide-9-layer-16" data-x={229} data-y={410} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:{-250,250};y:{-150,150};rX:{-90,90};rY:{-90,90};rZ:{-360,360};sX:0;sY:0;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="x:-50px;opacity:0;s:300;s:300;" data-start={5000} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 20 }}>MORE INFO</a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };
// export default Slider;


import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
  return (
    <div>
      <AutoplaySlider
        play={true}
        bullets={false}
        interval={2500}
        organicArrows={false}
        fillParent={false}
      >
        <div data-src="images/car_slider.jpg" />
        <div data-src="https://www.mvpatl.com/wp-content/uploads/2021/03/slide1b.jpg" />
        <div data-src="https://media-cdn.tripadvisor.com/media/photo-s/1c/c5/59/c0/rolls-royce-dawn-convertible.jpg" />
        <div data-src="https://mphclub.files.wordpress.com/2015/06/cropped-exotic-car-rental-miami.jpg" /> 

      </AutoplaySlider>
    </div>
  );
};
export default Slider;


