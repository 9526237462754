import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AdminHocComponent from "../hoc/AdminHoc";

const Fare = () => {

    // state 
    const [fare, setFare] = useState({
        base_price: '',
        rate_per_km: '',
    });

    /**
   * Method to handle the input field changes
   * @param {object} param0
   */
    const handleInputChange = ({
        target: { name = "", value = "" || [] } = {},
    }) => {
        setFare({ ...fare, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        // showLoader();
        e.preventDefault();
        await axios?.get('/sanctum/csrf-cookie').then(() => {
            axios?.post('/api/fare', fare).then((res) => {
                if (res?.data?.success === true) {
                    // hideLoader();
                    Swal.fire("success", res.data.message, "success");
                }
                else {
                    // hideLoader();
                    Swal.fire({
                        title: "Error!",
                        text: res.data.message,
                        type: "Error",
                        icon: 'error',
                        confirmButtonText: 'ok'
                    });
                }
            }).catch((error) => {
                console.log(error)
            })
        });
    }

    return (
        <>
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main" className="col-sm-12 col-md-12">
                        <div className="tab-container full-width-style arrow-left dashboard">
                            <div className="tab-content">
                                <div id="dashboard" className='tab-pane fade active in'>
                                    <form onSubmit={handleOnSubmit} className="booking-form">
                                        <div className="person-information">
                                            <h2>Cars Fare</h2>
                                            <div className="form-group row">
                                                <div className="col-sm-6 col-md-6">
                                                    <label>Base Price</label>
                                                    <input
                                                        type="text"
                                                        className="input-text full-width"
                                                        placeholder="Enter base price"
                                                        name="base_price"
                                                        value={fare?.base_price}
                                                        onChange={handleInputChange}
                                                    />
                                                    {/* <span className="text-danger "> {informationDetails.error.zipcode}</span> */}
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label>Rate Per/km</label>
                                                    <input
                                                        type="text"
                                                        className="input-text full-width"
                                                        placeholder="Enter rate per/km"
                                                        name="rate_per_km"
                                                        value={fare?.rate_per_km}
                                                        onChange={handleInputChange}
                                                    />
                                                    {/* <span className="text-danger"> {informationDetails.error.zipcode} </span> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row " style={{ marginTop: "25px" }}>
                                            <div className="col-sm-6 col-md-12">
                                                <button type="submit" className="full-width btn-large">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AdminHocComponent(Fare);