import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import FullPageLoader from "../../common/loader/FullPageLoader";

const ForgetPassword = () => {

    const [loader, showLoader, hideLoader] = FullPageLoader();

    const [userInput, setUserInput] = useState({
        email: "",
        error_list: [],
    });

    const handleInputChange = ({ target: { name, value } = {} }) => {
        setUserInput({ ...userInput, [name]: value })
    };

    
    const handleOnSubmit = async (e) => {
        showLoader();
        e.preventDefault();
       await axios?.get('/sanctum/csrf-cookie').then(() => {
         axios?.post(`/api/forget/password`, userInput).then((res) => {
                if (res?.data?.success === true) {
                    hideLoader();
                    Swal.fire("success", res.data.message, "success");
                }
                else {
                    if (res?.data?.errors) {
                        hideLoader();
                        setUserInput({ ...userInput, error_list: res?.data?.errors });
                    } else {
                        hideLoader();
                        Swal.fire({
                            title: "Error!",
                            text: res.data.message,
                            type: "Error",
                            icon: 'error',
                            confirmButtonText: 'ok'
                        });
                    }
                }
            }).catch ((error) => {
                console.log(error)
            })
        });
    }
    return (

        <div className="text-center ">
            <div className="">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <form onSubmit={handleOnSubmit}>
                            <h2 style={{ fontSize: "30px", marginTop: "20px" }}>Forget Password</h2>
                            <div className="customFormRow">
                                <div className="">
                                    <input style={{ width: "400px", height: "35px", marginTop: "10px" }} type="email" name="email" onChange={handleInputChange} value={userInput?.email} id="email" placeholder="Please Enter Your Email" />
                                    <span className="text-danger">{userInput?.error_list?.email}</span>

                                </div>
                            </div>
                            <div className="customFormRow">
                                <div className=" ">
                                    <button style={{ marginTop: "30px", width: "400px" }} type="submit" className="">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    )
}
export default ForgetPassword;