import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import FullPageLoader from "../../common/loader/FullPageLoader";
import Breadcrumb from "../layout/Breadcrumb";
import Sidebar from "../layout/Sidebar";
import FrontendHocComponent from "../hoc/FrontendHoc";

const EditUserProfile = () => {
  const { userId } = useParams();

  const navigate = useNavigate();

  const [loader, showLoader, hideLoader] = FullPageLoader();

  const [image, setImage] = useState([]);

  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob: "",
    street_address: "",
    state: "",
    district: "",
    city: "",
    country: "",
    zipcode: "",
    status: "",
  });

  const [isDisabled, setIsDisabled] = useState(false);

  // destructuring
  const {
    id,
    email,
    first_name,
    last_name,
    phone_number,
    dob,
    street_address,
    city,
    zipcode,
    country,
    state,
    district,
    status,
    user_type,
    phone_code
  } = userData;

  const fetchUserData = () => {
    showLoader();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`/api/get/profile/${userId}`).then((res) => {
        hideLoader();
        setUserData(res.data.result);
      });
    });
  };

  useEffect(() => {
    fetchUserData();
  }, [null]);

  const handleImage = (e) => {
    setImage({ image: e.target.files[0] });
  };

  const handleInputChange = ({
    target: { name = "", value = "" || [] } = {},
  }) => {
    setUserData({ ...userData, [name]: value });
  };

  const updateUserData = async (e) => {
    showLoader();
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData();

    formData.append("user_id", id);
    formData.append("user_type", user_type)
    formData.append("image", image.image);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("phone_number", phone_number);
    formData.append("phone_code", phone_code);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("city", city);
    formData.append("street_address", street_address);
    formData.append("zipcode", zipcode);
    formData.append("status", status);

    await axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`/api/update/profile/${userId}`, formData).then((res) => {
        if (res.data.result) {
          hideLoader();
          Swal.fire({ position: 'top-end', icon: 'success', title: res.data.message, showConfirmButton: true, timer: 1500 });
          //navigate('/dashboard');
          //navigate(`/dashboard/`,{page_name:'edit_profile'});
          navigate('/dashboard/', { replace: true, state: { page_name: 'edit_profile' } });
        }
        else {
          hideLoader();
          Swal.fire({ position: 'top-end', icon: 'error', title: res.data.message, showConfirmButton: false, timer: 1500 });

        }
        setIsDisabled(false);
      })
    });
  };

  return (
    <>
      {/* ========== breadcrum ==================  */}
      <Breadcrumb />
      <section id="content" className="gray-area">
        <div className="container">
          <div id="main" className="col-sm-12 col-md-12">
            <div className="tab-container full-width-style arrow-left dashboard">
              {/* ========== sidebar ==================  */}
              <Sidebar />
              <div className="tab-content">
                <div id="dashboard" className='tab-pane fade active in'>
                  <form onSubmit={(e) => updateUserData(e)} className="booking-form">
                    <div className="person-information">
                      <h2>Edit User</h2>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>first name</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your first name"
                            name="first_name"
                            value={first_name}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger">
                          {informationDetails.error.first_name}
                        </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>last name</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your last name"
                            name="last_name"
                            value={last_name}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger">
                  {informationDetails.error.last_name}
                </span> */}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>email address</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your email address"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger">
                  {informationDetails.error.email}
                </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>DOB</label>
                          <div className="datepicker-wrap">
                            <input
                              type="text"
                              name="dob"
                              value={dob}
                              onChange={handleInputChange}
                              className="input-text full-width hasDatepicker"
                              placeholder="mm/dd/yy"
                              id="dp1659024338042"
                            />
                            <img
                              className="ui-datepicker-trigger"
                              src="images/icon/blank.png"
                              alt=""
                              title=""
                            />
                            {/* <span className="text-danger">
                    {informationDetails.error.dob}
                  </span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>Phone number</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your phone number"
                            name="phone_number"
                            value={phone_number}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger">
                  {informationDetails.error.phone_number}
                </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>Country</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="country"
                              value={country}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Country</option>
                              <option value="India">India</option>
                            </select>
                            <span className="custom-select full-width">{country}</span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>State</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="state"
                              value={state}
                              onChange={handleInputChange}
                            >
                              <option value="">Select State</option>
                              <option value="Uttar Pardesh">Uttar Pardesh</option>
                            </select>
                            <span className="custom-select full-width">{state}</span>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>District</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="district"
                              value={district}
                              onChange={handleInputChange}
                            >
                              <option value="">Select District</option>
                              <option value="Ambedkarnagar">Ambedkarnagar</option>
                            </select>
                            <span className="custom-select full-width">{district}</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>City</label>
                          <div className="selector">
                            <select
                              className="full-width"
                              name="city"
                              value={city}
                              onChange={handleInputChange}
                            >
                              <option value="">Select City</option>
                              <option value="Akbarpur">Akbarpur</option>
                              <option value="Tanda">Tanda</option>
                              <option value="Rajesultanpur">Rajesultanpur</option>
                              <option value="Jalalpur">Jalalpur</option>
                              <option value="Ashrafpur Kichhauchha">
                                Ashrafpur Kichhauchha
                              </option>
                              <option value="Iltifatganj">Iltifatganj</option>
                              <option value="jahangir Ganj">Jahangir Ganj</option>
                            </select>
                            <span className="custom-select full-width">{city}</span>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>Street Address</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your street address"
                            name="street_address"
                            value={street_address}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger">
                  {informationDetails.error.street_address}
                </span> */}
                        </div>
                      </div>
                      {/* <div className="form-group row">
              <div className="col-sm-6 col-md-6">
                <label>Password</label>
                <input
                  type="password"
                  className="input-text full-width"
                  placeholder="Enter your password"
                  name="password"
                  value={informationDetails.password}
                  onChange={handleInputChange}
                />
                <span className="text-danger">
                  {informationDetails.error.password}
                </span>
              </div>
              <div className="col-sm-6 col-md-6">
                <label>Re-password</label>
                <input
                  type="password"
                  className="input-text full-width"
                  placeholder="Re-enter your password"
                  name="repassword"
                  value={informationDetails.repassword}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-6">
                          <label>Zip Code</label>
                          <input
                            type="text"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="zipcode"
                            value={zipcode}
                            onChange={handleInputChange}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <label>profile Image</label>
                          <input
                            type="file"
                            className="input-text full-width"
                            placeholder="Enter your Zip Code"
                            name="image"
                            onChange={handleImage}
                          />
                          {/* <span className="text-danger ">
                  {informationDetails.error.zipcode}
                </span> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={`checkbox status-checkbox ${status === "1" ? "checked" : ""
                            }`}
                        >
                          <label>
                            <input
                              type="checkbox"
                              name="status"
                              tabIndex={3}
                              value={status === "1" ? "0" : "1"}
                              onChange={handleInputChange}
                            />
                            Status
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row " style={{ marginTop: "25px" }}>
                      <div className="col-sm-6 col-md-12">
                        <button type="submit" className="full-width btn-large" disabled={isDisabled}>
                          UPDATE YOUR ACCOUNT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {loader}
          </div>
        </div>
      </section>
    </>
  );
};
export default FrontendHocComponent(EditUserProfile);
