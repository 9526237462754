import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FullPageLoader from "../../common/loader/FullPageLoader";

const Login = () => {

  const navigate = useNavigate();

  const [ loader, showLoader, hideLoader] = FullPageLoader();

  const [loginUser, setLoginUser] = useState({
    email: '',
    password: '',
    error_list: [],
  });

  const [isDisabled, setIsDisabled] = useState(false);

  // Destructure 
  const  { email, password } = loginUser;

  const handleInputChange = ({
    target: { name = "", value = "" || [] } = {},
  }) => {
    setLoginUser({ ...loginUser, [name]: value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    showLoader();
     setIsDisabled(true);
    await axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/api/user/login', loginUser).then(res => {
        if (res.data.result) {
          hideLoader();
          navigate('/dashboard');
          localStorage.setItem('auth_token', res.data.result);
          localStorage.setItem('user_id', res.data.user_id);
          if(res.data.user_role === 'admin') {
            localStorage.setItem('user_role', res.data.user_role);
            navigate('/admin/dashboard');
          }
          Swal.fire({ position: 'top-end', icon: 'success', title: res.data.message, showConfirmButton: false, timer: 1500 });
          
        }
       
        else {
          if (res.data.errors) {
            hideLoader();
            Swal.fire({ title: "Error!", text: res.data.message, type: "Error", icon: 'error', confirmButtonText: 'Cool' });
            setLoginUser({ ...loginUser, error_list: res.data.errors });
          } else {
          hideLoader();
            Swal.fire({
              title: "Error!",
              text: res.data.message,
              type: "Error",
              icon: 'error',
              confirmButtonText: 'ok'
            });

          }
        }
      }).catch(error => {
        console.log(error)
      });
    });
    setIsDisabled(false);
  }
  return (
    <div className="wrapper-blank soap-login-page style3 body-blank">
      <div id="page-wrapper" className="wrapper-blank">
        <section id="content">
          <div className="container">
            <div id="main">
              <h1 className="logo block">
                <Link to="index.html" title="Rentalcar - home">
                <img style={{borderRadius: '50%'}} width={114} height={64} src={"/images/logo3.jpg"} alt="rentalcar" />
                </Link>
              </h1>
              <div className="welcome-text box" style={{}}>
                Welcome back!
              </div>
              <p className="white-color block" style={{ fontSize: "1.5em" }}>
                Please login to your account.
              </p>
              <div className="col-sm-8 col-md-6 col-lg-5 no-float no-padding center-block">
                <form onSubmit={handleOnSubmit} className="login-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="input-text input-large full-width"
                      placeholder="enter your email or username"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                     <span>{loginUser.error_list ? loginUser.error_list.email : ''}</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="input-text input-large full-width"
                      placeholder="........"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                    />
                     <span>{loginUser.error_list ? loginUser.error_list.password : ''}</span>
                  </div>
                  <div className="form-group">
                    <Link to='/forgetpswrd' className="forgot-password pull-right">
                      Forgot password?
                    </Link>

                    <div className="checkbox " style={{width: "170px"}}>
                      <label className="checkbox">
                        <input type="checkbox" defaultValue />
                        remember my details
                      </label>
                    </div>
                  </div>
                  <button type="submit" className="btn-large full-width yellow" disabled={isDisabled}>
                    LOGIN TO YOUR ACCOUNT
                  </button>
                  <div className="seperator"></div>
                  <p className="dont-have-ac">
                    Don't have an account?{" "}
                    <Link to="/register" className="goto-signup soap-popupbox">
                      Sign up
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
          {loader}
        </section>
      </div>
    </div>
  );
};
export default Login;
