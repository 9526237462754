// NPM Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Component
import Breadcrumb from './layout/Breadcrumb';
import FullPageLoader from '../common/loader/FullPageLoader';

const CarDetails = () => {

    // use to get current route id
    const { id } = useParams();
    
    const navigate = useNavigate();
    let jsonTripDetails = localStorage.getItem('tripData');
    let auth_token = localStorage.getItem('auth_token');
    let tripDetails = JSON.parse(jsonTripDetails);
    let perdayPrice = sessionStorage.getItem("kmPrice");


    // Destructure
    const [loader, showLoader, hideLoader] = FullPageLoader();


    // States
    const [carDatas, setCarDatas] = useState([]);
    const [carsList, setCarsList] = useState([]);
   

    /**
     * Method to fetch car data targeting id from DB.
     */
    const fetchCarDatas = () => {
        axios.get(`/api/car/details/${id}`).then(res => {
            if (res.data.result) {
                setCarDatas(res.data.result);
            }
            // setLoading(false);
        });
    }

    // Destructring the api response datas
    const {
        additional_information,
        air_conditioning,
        automatic_transmission,
        car_image,
        car_model_number,
        car_name,
        car_number,
        car_type,
        created_at,
        fuel_type,
        how_many_year_old,
        location,
        mileage,
        satellite_navigation,
        seats,

    } = carDatas

    /**
     * Method to fetch cars list from DB.
     */
    const fetchCarsList = async () => {
        showLoader()
       await axios.get(`/api/list/car/info`).then(res => {
            if (res.data.result) {
                hideLoader()
                setCarsList(res.data.result);
            }
           
        });
    }

   

    /**
     * Component Did Mount
     */
    useEffect(() => {
        fetchCarDatas();
        fetchCarsList();
    }, []);


    return (
        <>
            {/* ========== breadcrum ==================  */}

            <Breadcrumb />

            <section id="content" className="gray-area">
                <div className="container car-detail-page">
                    <div className="row">
                        <div id="main" className="col-md-9">
                            <div className="featured-image box">
                                <img src={car_image} alt="rentalcar" />
                            </div>
                            <div className="tab-container">
                                <ul className="tabs">
                                    <li className="active">
                                        <a href="#car-details" data-toggle="tab">Car Details</a>
                                    </li>
                                    <li>
                                        <Link to="/car" >Upgrade Your Car</Link>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade in active" id="car-details">
                                        <div className="intro box table-wrapper full-width hidden-table-sms">
                                            <div className="col-sm-4 table-cell travelo-box">
                                                <dl className="term-description">
                                                    <dt>Rental Company:</dt><dd>Ez Rent a Car</dd>
                                                    <dt>Car Type:</dt><dd>{car_type}</dd>
                                                    <dt>Car name:</dt><dd>{car_name}</dd>
                                                    <dt>Passenger:</dt><dd>{seats}</dd>
                                                    <dt>Car features:</dt><dd>Available
                                                    </dd><dt>Taxes &amp; Fees:</dt><dd>$155.61</dd>
                                                    <dt>total price:</dt><dd>$201</dd>
                                                </dl>
                                            </div>
                                            <div className="col-sm-8 table-cell">
                                                <div className="detailed-features clearfix">
                                                    <div className="col-md-4">
                                                        <h4 className="box-title">
                                                            Pick-up location details
                                                            <small>Phone: 1-0825889755</small>
                                                        </h4>
                                                        <div className="icon-box style11">
                                                            <div className="icon-wrapper">
                                                                <i className="soap-icon-clock" />
                                                            </div>
                                                            <dl className="details">
                                                                <dt className="skin-color">pickup time</dt>
                                                                <dd>{tripDetails?.pick_up_date}</dd>
                                                                <dd>{tripDetails?.pick_up_time}</dd>
                                                            </dl>
                                                        </div>
                                                        <div className="icon-box style11">
                                                            <div className="icon-wrapper">
                                                                <i className="soap-icon-departure" />
                                                            </div>
                                                            <dl className="details">
                                                                <dt className="skin-color">Location</dt>
                                                                <dd>{tripDetails?.pick_up_place}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <h4 className="box-title">
                                                            Drop-off location details
                                                            <small>Phone: 1-0825889755</small>
                                                        </h4>
                                                        <div className="icon-box style11">
                                                            <div className="icon-wrapper">
                                                                <i className="soap-icon-clock" />
                                                            </div>
                                                            <dl className="details">
                                                                <dt className="skin-color">Drop off Time</dt>
                                                                <dd>{tripDetails?.drop_off_date}</dd>
                                                                <dd>{tripDetails?.drop_off_time}</dd>
                                                            </dl>
                                                        </div>
                                                        <div className="icon-box style11">
                                                            <div className="icon-wrapper">
                                                                <i className="soap-icon-departure" />
                                                            </div>
                                                            <dl className="details">
                                                                <dt className="skin-color">Location</dt>
                                                                <dd>{tripDetails?.drop_off_place}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 table-cell travelo-box">
                                                        <h4 className="box-title">
                                                            Other trip details full
                                                            <small>Phone: 1-0825889755</small>
                                                        </h4>
                                                        <dl className="term-description box-title">
                                                            <dt>adults:</dt><dd>{tripDetails?.adults}</dd>
                                                            <dt>kids:</dt><dd>{tripDetails?.kids}</dd>
                                                            <dt>car type:</dt><dd>{tripDetails?.car_type}</dd>
                                                        </dl>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Car Rental Information</h2>
                                        <p>{additional_information}</p>
                                        <br />
                                        <div className="car-features box">
                                            <div className="row add-clearfix">
                                                <div className="col-sms-6 col-sm-6 col-md-4">
                                                    <span className="icon-box style2">
                                                        <i className="soap-icon-user circle" />{seats} Passengers
                                                    </span>
                                                </div>
                                                <div className="col-sms-6 col-sm-6 col-md-4">
                                                    <span className="icon-box style2">
                                                        <i className="soap-icon-aircon circle" />{air_conditioning}
                                                    </span>
                                                </div>
                                                <div className="col-sms-6 col-sm-6 col-md-4">
                                                    <span className="icon-box style2">
                                                        <i className="soap-icon-fmstereo circle" />{satellite_navigation}
                                                    </span>
                                                </div>
                                                <div className="col-sms-6 col-sm-6 col-md-4">
                                                    <span className="icon-box style2">
                                                        <i className="soap-icon-fueltank circle" />{fuel_type} Vehicle
                                                    </span>
                                                </div>
                                                <div className="col-sms-6 col-sm-6 col-md-4">
                                                    <span className="icon-box style2">
                                                        <i className="soap-icon-automatic-transmission circle" />{automatic_transmission}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar col-md-3">
                            <article className="detailed-logo">
                                <figure>
                                    <img width={114} height={85} src={"/images/hotels/logo/1.jpg"} alt="rentalcar" />
                                </figure>
                                <div className="details">
                                    <h2 className="box-title">{car_name}<small>{car_type} car</small></h2>
                                    <span className="price clearfix">
                                        <small className="pull-left">per day</small>
                                        <span className="pull-right">₹{perdayPrice}</span>
                                    </span>
                                    <div className="mile clearfix">
                                        <span className="skin-color">Mileage:</span>
                                        <span className="mileage pull-right">{mileage} Miles</span>
                                    </div>
                                    <p className="description">{additional_information}</p>
                                { auth_token 
                                ?  <button onClick={() =>  navigate('/booking', { state: { id, carDatas } })} className="button yellow full-width uppercase btn-small">Booking</button> 
                                :  <button onClick={() =>  navigate('/login')} className="button yellow full-width uppercase btn-small">Booking</button>
                            }
                                </div>
                            </article>

                        </div>
                    </div>
                </div>
                {loader}
            </section>

        </>


    );
};
export default CarDetails;


