// NPM pakages
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BallTriangle } from "react-loader-spinner";

// Component
import FullPageLoader from "../../common/loader/FullPageLoader";

/**
 * Method to handle the user Register 
 * @returns node
 */
const Register = () => {

  const navigate = useNavigate();

  // Destructure
  const [ loader, showLoader, hideLoader ]  = FullPageLoader();

  // State
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_type: "user",
    user_role: "3",
    status: "",
    phone_code: "+91",
    dob: "",
    phone_number: "",
    country: "Select Country",
    state: "Select State",
    district: "Select District",
    city: "Select City",
    password: "",
    password_confirmation: "",
    street_address: "",
    zipcode: "",
    error: [],
  });

  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * Method to handle the input field changes
   * @param {object} param0
   */
  const handleInputChange = ({
    target: { name = "", value = "" || [] } = {},
  }) => {
    setUserDetails({ ...userDetails, [name]: value });
  };

  /**
   * Method to handle the user type
   * @param {*} param0
   */
  const handleUserType = ({ user_type, user_role }) => {
    userDetails.user_type = user_type;
    userDetails.user_role = user_role;
  };

  /**
   * Method to handle on submit 
   * @param {object} e 
   */
  const handleOnSubmit = (e) => {
    showLoader();
    e.preventDefault(e);

    setIsDisabled(true);

    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`/api/user/register`, userDetails).then((res) => {
        if (res.data.result) {
          hideLoader();
          Swal.fire({
            title: "success",
            text: res.data.message,
          });
          setIsDisabled(false);
          navigate('/login')
        } else {
          hideLoader();
          setUserDetails({
            ...userDetails,
            error: res.data.errors,
          });
          setIsDisabled(false);
        }
      });
    });
  };

  return (
    <div className="wrapper-blank soap-login-page style3 body-blank">
  
      <div id="page-wrapper" className="wrapper-blank">
        <section id="content">
          <div className="container">
            <div id="main">
              <h1 className="logo block">
                <a href="/" title="Rentalcar - home">
                <img style={{borderRadius: '50%' , marginBottom: -20}} width={114} height={65} src={"/images/logo3.jpg"} alt="rentalcar" />
                </a>
              </h1>
              <div className="tab-container style1">
                <ul className="tabs full-width">
                  <li className="active">
                    <a
                      href="#user-layouts"
                      data-toggle="tab"
                      aria-expanded="true"
                      onClick={() =>
                        handleUserType({ user_type: "user", user_role: "3" })
                      }
                    >
                      User
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="#driver-layouts"
                      data-toggle="tab"
                      aria-expanded="false"
                      onClick={() =>
                        handleUserType({ user_type: "driver", user_role: "2" })
                      }
                    >
                      Driver
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {/* USER INFORMATION */}
                  <div className="tab-pane fade active in" id="user-layouts">
                    <div className="row">
                      <div id="main" className="col-sm-12 col-md-12">
                        <form
                          onSubmit={(e) => handleOnSubmit(e)}
                          className="booking-form"
                        >
                          <div className="person-information">
                            <h2>User Informantion</h2>
                            <div className="form-group row">
                              <input
                                type="hidden"
                                name="user_type"
                                value={userDetails.user_type}
                              />
                              <input
                                type="hidden"
                                name="user_role"
                                value={userDetails.user_role}
                              />
                              <div className="col-sm-6 col-md-6">
                                <label>first name</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your first name"
                                  name="first_name"
                                  value={userDetails.first_name}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.first_name}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>last name</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your last name"
                                  name="last_name"
                                  value={userDetails.last_name}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.last_name}
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>email address</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your email address"
                                  name="email"
                                  value={userDetails.email}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.email}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>DOB</label>
                                <div className="datepicker-wrap">
                                  <input
                                    type="text"
                                    name="dob"
                                    value={userDetails.dob}
                                    onChange={handleInputChange}
                                    className="input-text full-width hasDatepicker"
                                    placeholder="mm/dd/yy"
                                    id="dp1659024338042"
                                  />
                                  <img
                                    className="ui-datepicker-trigger"
                                    src="images/icon/blank.png"
                                    alt=""
                                    title=""
                                  />
                                  <span className="text-danger">
                                    {userDetails.error.dob}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Phone number</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your phone number"
                                  name="phone_number"
                                  value={userDetails.phone_number}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.phone_number}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Country</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="country"
                                    value={userDetails.country}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select Country</option>
                                    <option value="India">India</option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.country}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>State</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="state"
                                    value={userDetails.state}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select State</option>
                                    <option value="Uttar Pardesh">
                                      Uttar Pardesh
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.state}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>District</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="district"
                                    value={userDetails.district}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select District</option>
                                    <option value="Ambedkarnagar">
                                      Ambedkarnagar
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.district}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>City</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="city"
                                    value={userDetails.city}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select City</option>
                                    <option value="Akbarpur">Akbarpur</option>
                                    <option value="Tanda">Tanda</option>
                                    <option value="Rajesultanpur">
                                      Rajesultanpur
                                    </option>
                                    <option value="Jalalpur">Jalalpur</option>
                                    <option value="Ashrafpur Kichhauchha">
                                      Ashrafpur Kichhauchha
                                    </option>
                                    <option value="Iltifatganj">
                                      Iltifatganj
                                    </option>
                                    <option value="jahangir Ganj">
                                      Jahangir Ganj
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.city}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Street Address</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your street address"
                                  name="street_address"
                                  value={userDetails.street_address}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.street_address}
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Password</label>
                                <input
                                  type="password"
                                  className="input-text full-width"
                                  placeholder="Enter your password"
                                  name="password"
                                  value={userDetails.password}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.password}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Re-password</label>
                                <input
                                  type="password"
                                  className="input-text full-width"
                                  placeholder="Re-enter your password"
                                  name="password_confirmation"
                                  value={userDetails.password_confirmation}
                                  onChange={handleInputChange}
                                />
                                 <span className="text-danger">
                                  {userDetails.error.password_confirmation}
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Zip Code</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your Zip Code"
                                  name="zipcode"
                                  value={userDetails.zipcode}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger ">
                                  {userDetails.error.zipcode}
                                </span>
                              </div>
                              <div className="form-group">
                                <div>
                                  <label>
                                    <input
                                      type="radio"
                                      name="alert_message_type"
                                      tabIndex={3}
                                      value="0"
                                      onChange={handleInputChange}
                                    />
                                    By Email
                                    <input
                                      type="radio"
                                      name="alert_message_type"
                                      className="radio-input"
                                      tabIndex={3}
                                      value="1"
                                      onChange={handleInputChange}
                                    />
                                    By Text
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div
                              className={`checkbox status-checkbox ${
                                userDetails.status === "1"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  name="status"
                                  tabIndex={3}
                                  value={
                                    userDetails.status === "1"
                                      ? "0"
                                      : "1"
                                  }
                                  onChange={handleInputChange}
                                />
                                Status
                              </label>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-sm-6 col-md-12">
                              <button
                                type="submit"
                                className="full-width btn-large"
                                disabled={isDisabled}
                              >
                                REGISTER YOUR ACCOUNT
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* DRIVER INFORMATION */}
                  <div className="tab-pane fade" id="driver-layouts">
                    <div className="row">
                      <div id="main" className="col-sm-12 col-md-12">
                        <form
                          onSubmit={(e) => handleOnSubmit(e)}
                          className="booking-form"
                        >
                          <div className="person-information">
                            <h2>Driver Informantion</h2>
                            <div className="form-group row">
                              <input
                                type="hidden"
                                name="user_type"
                                value={userDetails.user_type}
                              />
                              <input
                                type="hidden"
                                name="user_role"
                                value={userDetails.user_role}
                              />
                              <div className="col-sm-6 col-md-6">
                                <label>first name</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your first name"
                                  name="first_name"
                                  value={userDetails.first_name}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.first_name}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>last name</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your last name"
                                  name="last_name"
                                  value={userDetails.last_name}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.last_name}
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>email address</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your email address"
                                  name="email"
                                  value={userDetails.email}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.email}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>DOB</label>
                                <div className="datepicker-wrap">
                                  <input
                                    type="text"
                                    name="dob"
                                    value={userDetails.dob}
                                    onChange={handleInputChange}
                                    className="input-text full-width hasDatepicker"
                                    placeholder="mm/dd/yy"
                                    id="dp1659024338042"
                                  />
                                  <img
                                    className="ui-datepicker-trigger"
                                    src="images/icon/blank.png"
                                    alt=""
                                    title=""
                                  />
                                  <span className="text-danger">
                                    {userDetails.error.dob}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Phone number</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your phone number"
                                  name="phone_number"
                                  value={userDetails.phone_number}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.phone_number}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Country</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="country"
                                    value={userDetails.country}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select Country</option>
                                    <option value="India">India</option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.country}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>State</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="state"
                                    value={userDetails.state}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select State</option>
                                    <option value="Uttar Pardesh">
                                      Uttar Pardesh
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.state}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>District</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="district"
                                    value={userDetails.district}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select District</option>
                                    <option value="Ambedkarnagar">
                                      Ambedkarnagar
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.district}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>City</label>
                                <div className="selector">
                                  <select
                                    className="full-width"
                                    name="city"
                                    value={userDetails.city}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select City</option>
                                    <option value="Akbarpur">Akbarpur</option>
                                    <option value="Tanda">Tanda</option>
                                    <option value="Rajesultanpur">
                                      Rajesultanpur
                                    </option>
                                    <option value="Jalalpur">Jalalpur</option>
                                    <option value="Ashrafpur Kichhauchha">
                                      Ashrafpur Kichhauchha
                                    </option>
                                    <option value="Iltifatganj">
                                      Iltifatganj
                                    </option>
                                    <option value="jahangir Ganj">
                                      Jahangir Ganj
                                    </option>
                                  </select>
                                  <span className="custom-select full-width">
                                    {userDetails.city}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Local Market</label>
                                <div className="selector">
                                  <div className="selector">
                                    <select
                                      className="full-width"
                                      name="local_market"
                                      value={userDetails.local_market}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Select Local Market
                                      </option>
                                      <option value="Akbarpur">Akbarpur</option>
                                      <option value="Baskhari">Baskhari</option>
                                      <option value="Bhiti">Bhiti</option>
                                      <option value="Bhiyaon">Bhiyaon</option>
                                      <option value="Jahangir Ganj">
                                        Jahangir Ganj
                                      </option>
                                      <option value="Jalalpur">Jalalpur</option>
                                      <option value="Katehari">Katehari</option>
                                      <option value="Ramnagar, Alapur">
                                        Ramnagar, Alapur
                                      </option>
                                      <option value="Tanda">Tanda</option>
                                      <option value="Rajesultanpur">
                                        Rajesultanpur
                                      </option>
                                      <option value="Hanswar">Hanswar</option>
                                      <option value="Jahangir Ganj">
                                        Jahangir Ganj
                                      </option>
                                    </select>
                                    <span className="custom-select full-width">
                                      {userDetails.local_market}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Password</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your password"
                                  name="password"
                                  value={userDetails.password}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.password}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Re-password</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Re-enter your password"
                                  name="password_confirmation"
                                  value={userDetails.password_confirmation}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <label>Street Address</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your street address"
                                  name="street_address"
                                  value={userDetails.street_address}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.street_address}
                                </span>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <label>Zip Code</label>
                                <input
                                  type="text"
                                  className="input-text full-width"
                                  placeholder="Enter your Zip Code"
                                  name="zipcode"
                                  value={userDetails.zipcode}
                                  onChange={handleInputChange}
                                />
                                <span className="text-danger">
                                  {userDetails.error.zipcode}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="form-group">
                            <div
                              className={`checkbox status-checkbox ${
                                userDetails.status === "1"
                                  ? "checked"
                                  : "!checked"
                              }`}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  name="status"
                                  tabIndex={3}
                                  value={
                                    userDetails.status === "1"
                                      ? "0"
                                      : "1"
                                  }
                                  onChange={handleInputChange}
                                />
                                Status
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-6 col-md-12">
                              <button
                                type="submit"
                                className="full-width btn-large"
                                disabled={isDisabled}
                              >
                                REGISTER YOUR ACCOUNT
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loader}
        </section>
      </div>
    </div>
  );
};
export default Register;
