import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import FullPageLoader from "../../common/loader/FullPageLoader";
import AdminHocComponent from "../hoc/AdminHoc";

const EditUser = () => {


    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState([]);

    const [error, setError] = useState([]);
    const [loader, showLoader, hideLoader] = FullPageLoader();



    const handleInput = (e) => {
        e.persist();
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        axios.get(`api/get/profile/${id}`).then(res => {
            if (res.data.result) {
                setUserInput(res.data.result);
            } else if (res.data.success === false) {
                Swal.fire("warning", res.data.message, "error");
            }
            setLoading(false);
        });

    }, [id]);

    if (loading) {
        return <h4>Loading Edit User...</h4>
    }

    const updateUser = (e) => {
        e.preventDefault();
        showLoader();
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/update/profile/${id}`, userInput).then(res => {
                if (res.data.result) {
                    hideLoader();
                    Swal.fire("success", "User Updated Successfully Done", "success");
                    setError([])
                }
                else {
                    hideLoader();
                    setError(res.data.errors);
                }
            });
        });
    }


    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">

                <article className="image-box box innerstyle personal-details">
                    <div className="details">
                        {/* <a href="#" className="button btn-mini pull-right edit-profile-btn">EDIT PROFILE</a> */}
                        <h2 className="box-title fullname">EDIT USER</h2>
                    </div>
                </article>
                <div className="card-body">

                    <form onSubmit={updateUser}>


                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="hidden" id="__postSearchLocationAdress" name="street_address" onChange={handleInput} value={userInput.street_address} />

                                    <input type="hidden" id="__postLatitude" name="latitude" onChange={handleInput} value={userInput.latitude} />
                                    <input type="hidden" id="__postLongitude" name="longitude" onChange={handleInput} value={userInput.longitude} />

                                    <label htmlFor="name">First Name</label>
                                    <input type="text" name="first_name" onChange={handleInput} value={userInput.first_name} className="form-control" placeholder="Enter First Name" />
                                    <span className="validate-error-message">{error.first_name}</span>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="name">Last Name</label>
                                    <input type="text" name="last_name" onChange={handleInput} value={userInput.last_name} className="form-control" placeholder="Enter Last Name" />
                                    <span className="validate-error-message">{error.last_name}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="userName">User Name</label>
                                    <input type="text" name="user_name" onChange={handleInput} value={userInput.user_name} className="form-control" placeholder="Enter User Name" />
                                    <span className="validate-error-message">{error.user_name}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" onChange={handleInput} value={userInput.email} className="form-control" placeholder="Enter Email" />
                                    <span className="validate-error-message">{error.email}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input type="text" name="company_name" onChange={handleInput} value={userInput.company_name} className="form-control" placeholder="Enter Company Name" />
                                    <span className="validate-error-message">{error.company_name}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="streetAddress">Street Address</label>

                                    <input type="text" name="street_address" onChange={handleInput} value={userInput.street_address} className="form-control" placeholder="Enter Street Address" />

                                    {/* {isDisplayed &&
                                        <AdminAutoComplete
                                            apiKey={'AIzaSyDhxj1eT2udQsZgkkqkCSkTkAOGv-yiu-Q'}
                                            setCoodinationValueAddress={setCoodinationValueAddress}
                                            addressLocation={addressLocation}
                                        />
                                    } */}
                                    <span className="validate-error-message">{error.street_address}</span>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="streetAddress2">Street Address 2</label>
                                    <input type="text" name="street_address_2" id="__postSearchLocationAdress2" onChange={handleInput} value={userInput.street_address_2} className="form-control" placeholder="Enter Street Address 2" />
                                    <span className="validate-error-message">{error.street_address_2}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="City">City</label>
                                    <input type="text" name="city" id="__postSearchLocationCity" onChange={handleInput} value={userInput.city} className="form-control" placeholder="Enter City" />
                                    <span className="validate-error-message">{error.city}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="State">State</label>
                                    <input type="text" name="state" id="__postSearchLocationState" onChange={handleInput} value={userInput.state} className="form-control" placeholder="Enter State" />
                                    <span className="validate-error-message">{error.state}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input type="text" name="phone_number" onChange={handleInput} value={userInput.phone_number} className="form-control" placeholder="Enter Phone Number" />
                                    <span className="validate-error-message">{error.phone_number}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    {/* <label htmlFor="State">User Type</label> */}
                                    {/* <select name="user_type" onChange={handleInput} value={userInput.user_type} className="form-control">
                                        <option value="">Select User Type</option>
                                        <option value="contractor" selected={userInput.user_type == 'contractor' ? 'selected' : ''}>Contractor</option>
                                        <option value="trucking_company" selected={userInput.user_type == 'trucking_company' ? 'selected' : ''}>Trucking Company</option>

                                    </select> */}
                                    <span className="validate-error-message">{error.user_type}</span>
                                </div>
                            </div>
                            <div className="col-md-6 user-notify-type">
                                <div className="form-group">
                                    <label className="customCheckBox">
                                        <input type="checkbox" name="alert_message_type" onChange={handleInput} value="1" checked={userInput.alert_message_type === '1' ? 'checked' : ''} />
                                        <span className="checkmark"></span>Email Alert
                                    </label>
                                    <label className="customCheckBox">
                                        <input type="checkbox" name="alert_message_type" onChange={handleInput} value="0" checked={userInput.alert_message_type === '0' ? 'checked' : ''} />
                                        <span className="checkmark"></span>Text Messages
                                    </label>
                                </div>
                                <span className="validate-error-message">{error.alert_message_type}</span>

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-12 text-right pull-right">
                                <button type="submit" className="button btn-large dark-blue1 btn-admin-panel">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
                {loader}
            </div>

        </div>
    );
}
export default AdminHocComponent(EditUser);