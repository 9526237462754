import React from 'react';

const  Page404 = () => {

    return (
        <div className="post-404page style1">
          <section id="content">
            <div className="container">
                <div id="main">
                    <div className="col-md-6 col-sm-9 no-float no-padding center-block">
                        <div className="error-message">The page <i>you were looking for</i> could not be found.</div>
                    </div>
                    <div className="error-message-404">
                        404
                    </div>
                </div>
            </div>
        </section>
        </div>

       
       
    )
}
export default Page404;