import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "./layout/Breadcrumb";
import Settings from "../frontend/auth/Settings";
import FrontendDashboard from "./dashboard/FrontendDashboard";
import Sidebar from "./layout/Sidebar";
import ViewProfile from "./auth/ViewProfile";
import FrontendHocComponent from "./hoc/FrontendHoc";
import ViewBookingDetails from "./ViewBookingDetails";
import CarList from "./car/CarList";





const Dashboard = (props) => {

        
    const location = useLocation();
    const [isActive, setIsActive] = useState('dashboard');

    const tab = props.tab ? props.tab : '';

    useEffect(() => {
        setIsActive(tab);
    }, [])


    return (
        <>
            {/* ========== breadcrum ==================  */}
             <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    <div id="main">
                        <div className="tab-container full-width-style arrow-left dashboard">

                            {/* ========== sidebar ==================  */}

                            <Sidebar/>

                            <div className="tab-content">

                                <div id="dashboard" className={isActive === 'dashboard' ? 'tab-pane fade active in' : 'tab-pane fade'}>
                                    <FrontendDashboard />
                                </div>
                            
                                <div id="profile" className={isActive === 'profile'  ? 'tab-pane fade active in' : 'tab-pane fade'} >
                                    <ViewProfile/>
                                </div>
                                <div id="carInfo" className={isActive === 'carInfo'  ? 'tab-pane fade active in' : 'tab-pane fade'} >
                                    <CarList/>
                                </div>
                                <div id="settings" className={isActive === 'setting' ? 'tab-pane fade active in' : 'tab-pane fade'}>
                                    <Settings />
                                </div>
                                <div id="booking" className={isActive === 'booking' ? 'tab-pane fade active in' : 'tab-pane fade'}>
                                    <ViewBookingDetails />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Dashboard;
