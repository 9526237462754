import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../../common/loader/FullPageLoader";


const Header = () => {

    document.title = "RentalCars - Header-Backend";

    const navigate = useNavigate();

    const [loader, showLoader, hideLoader] = FullPageLoader();

    const user = localStorage.getItem("loggedInUser");

    const data = {
        auth_token: localStorage.getItem('auth_token'),
        user_id: localStorage.getItem('user_id'),
        user_role: localStorage.getItem('user_role')
    }

    const handleOnLogout = (e) => {
        showLoader();
        axios.post('/api/user/logout', data).then(res => {
            if (res.data.status_code === 200) {
                hideLoader();
                localStorage.removeItem('auth_token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_role');
                Swal.fire({ position: 'top-end', icon: 'success', title: res.data.message, showConfirmButton: false, timer: 1500 });
                navigate('/')
            } else {
                hideLoader();
            }
        }).catch(error => {
            console.log(error)
        });
    }
    return (
        <header id="header" className="navbar-static-top main-header">
            <a href="#mobile-menu-01" data-toggle="collapse" className="mobile-menu-toggle blue-bg">Mobile Menu Toggle</a>
            <div className="container">
                <h1 className="navbar-brand" style={{padding : 0}}>
                    <Link to="/" title="Rentatcar - home">
                    <img style={{borderRadius: '50%'}} width={114} height={64} src={"/images/logo3.jpg"} alt="rentalcar" />
                    </Link>
                </h1>

                <nav id="mobile-menu-01" className="mobile-menu collapse">
                    <ul id="mobile-primary-menu" className="menu">
                        <li className="menu-item-has-children">
                            <Link to="/">Home</Link>
                            <ul>
                                <li>
                                    <a href="index.html">Home Layout 1</a>
                                </li>
                                <li>
                                    <a href="homepage2.html">Home Layout 2</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <ul className="quick-menu pull-right hidden-mobile">
                    <li className="ribbon menu-color-blue">
                        <a href="#">{user}</a>
                        <ul className="menu mini uppercase left">
                            <li><Link onClick={handleOnLogout} to="/">Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </div>
            {loader}
        </header>

    );
};

export default Header;
