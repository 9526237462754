import React from 'react';
import { Link } from "react-router-dom";
import Breadcrumb from './layout/Breadcrumb';


const ThankYou = () => {
    return (
        <>
            {/* ========== breadcrum ==================  */}

            <Breadcrumb />
            <section id="content" className="gray-area">
                <div className="container">
                    <div className="row">
                        <div id="main" className="col-sm-8 col-md-9">
                            <div className="booking-information travelo-box">
                                <h2>Booking Confirmation</h2>
                                <hr />
                                <div className="booking-confirmation clearfix">
                                    <i className="soap-icon-recommend icon circle" />
                                    <div className="message">
                                        <h4 className="main-message">Thank You. Your Booking Order is Confirmed Now.</h4>
                                        <p>A confirmation email has been sent to your provided email address.</p>
                                    </div>
                                    <a href="#" className="button print-button btn-small uppercase">print Details</a>
                                </div>
                                <hr />
                                <h2>Traveler Information</h2>
                                <dl className="term-description">
                                    <dt>Booking number:</dt><dd>5784-BD245</dd>
                                    <dt>First name:</dt><dd>Jessica</dd>
                                    <dt>Last name:</dt><dd>Brown</dd>
                                    <dt>E-mail address:</dt><dd>Info@Jessica.com</dd>
                                    <dt>Street Address and number:</dt><dd>353 Third floor Avenue</dd>
                                    <dt>Town / City:</dt><dd>Paris,France</dd>
                                    <dt>ZIP code:</dt><dd>75800-875</dd>
                                    <dt>Country:</dt><dd>United States of america</dd>
                                </dl>
                                <hr />
                                <h2>Payment</h2>
                                <p>Praesent dolor lectus, rutrum sit amet risus vitae, imperdiet cursus neque. Nulla tempor nec lorem eu suscipit. Donec dignissim lectus a nunc molestie consectetur. Nulla eu urna in nisi adipiscing placerat. Nam vel scelerisque magna. Donec justo urna, posuere ut dictum quis.</p>
                                <br />
                                <p className="red-color">Payment is made by Credit Card Via Paypal.</p>
                                <hr />
                                <h2>View Booking Details</h2>
                                <p>Praesent dolor lectus, rutrum sit amet risus vitae, imperdiet cursus neque. Nulla tempor nec lorem eu suscipit. Donec dignissim lectus a nunc molestie consectetur. Nulla eu urna in nisi adipiscing placerat. Nam vel scelerisque magna. Donec justo urna, posuere ut dictum quis.</p>
                                <br />
                                <a href="#" className="red-color underline view-link">https://www.travelo.com/booking-details/?=f4acb19f-9542-4a5c-b8ee</a>
                            </div>
                        </div>
                        <div className="sidebar col-sm-4 col-md-3">
                            {/* <div className="travelo-box contact-box">
                                <h4>Need Travelo Help?</h4>
                                <p>We would be more than happy to help you. Our team advisor are 24/7 at your service to help you.</p>
                                <address className="contact-details">
                                    <span className="contact-phone"><i className="soap-icon-phone" /> 1-800-123-HELLO</span>
                                    <br />
                                    <a className="contact-email" href="#">help@travelo.com</a>
                                </address>
                            </div> */}
                            {/* <div className="travelo-box book-with-us-box">
                                <h4>Why Book with us?</h4>
                                <ul>
                                    <li>
                                        <i className="soap-icon-hotel-1 circle" />
                                        <h5 className="title"><a href="#">135,00+ Hotels</a></h5>
                                        <p>Nunc cursus libero pur congue arut nimspnty.</p>
                                    </li>
                                    <li>
                                        <i className="soap-icon-savings circle" />
                                        <h5 className="title"><a href="#">Low Rates &amp; Savings</a></h5>
                                        <p>Nunc cursus libero pur congue arut nimspnty.</p>
                                    </li>
                                    <li>
                                        <i className="soap-icon-support circle" />
                                        <h5 className="title"><a href="#">Excellent Support</a></h5>
                                        <p>Nunc cursus libero pur congue arut nimspnty.</p>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
};
export default ThankYou;


