// npm packages
import React from "react";
import { Outlet } from "react-router-dom";

// components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import AdminHocComponent from "../hoc/AdminHoc";

const AdminLayout = () => (
	<>
		<div className="dashboard style1">
			<div id="page-wrapper">
				<Header />
				<section id="content" className="gray-area">
					<div className="container">
						<div id="main">
							<div className="tab-container full-width-style arrow-left dashboard">
								{/* ========== sidebar ==================  */}
								<Sidebar />
								<div className="tab-content ">
									<Outlet />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	</>
);
export default AdminLayout;