// npm packages
import React from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

// component
import Home from "../../frontend/Home";


const AdminHocComponent = (OriginalComponent) => {

    return (props) => {
        // const auth_token = localStorage.getItem('auth_token');
        const admin = localStorage.getItem('user_role');
        
        const renderComponent = () => {


            if (admin) {
               return <OriginalComponent {...props} />
            }
            return (
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are not a admin user!',
                    confirmButtonColor: '#3090C7',
                    footer: '<a href="/login">Click here to login first!</a>'
                }),
                <Navigate to="/" replace={true}  />
                )
        };
        return <>{renderComponent()}</>;
    };
};
export default AdminHocComponent;