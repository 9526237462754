import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import FullPageLoader from "../common/loader/FullPageLoader";
import PaginationComponent from "../data_table/pagination";
import { Link } from "react-router-dom";
import SearchUser from "../data_table/search";
import AdminHocComponent from "./hoc/AdminHoc";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewBookingDetails = () => {
    const [data, setData] = useState([]);
    const [loader, showLoader, hideLoader] = FullPageLoader();
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const ITEMS_PER_PAGE = 5;
    const [enteredAmount, setEnteredAmount] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookingId, setBookingId] = useState(null);
    const [errors, setErrors] = useState([]);

    const fetchBookingDetails = () => {
        showLoader();
        axios.get(`/api/get/booking`).then((res) => {
            if (res.data.result) {
                hideLoader();
                const sortedData = res.data.result.sort((a, b) => a.id - b.id);
                setData(sortedData);
            }
        });
    };


    const handleInputChange = (e) => {
        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
        setEnteredAmount(sanitizedValue);
    };

    const handleUpdate = async () => {
        if(bookingId) {
            await axios.get("/sanctum/csrf-cookie").then(() => {
                axios.post(`/api/update/booking/amount`, {booking_id: bookingId, amount: parseFloat(enteredAmount)}).then((res) => {
                  if (res.data.success) {
                    hideLoader();
                    Swal.fire({ icon: 'success', title: "Amount Updated Successfully", showConfirmButton: true, timer: 1500 });
                  }
                  else {
                    hideLoader();
                    toast.error(res.data.message || res.data.errors.amount[0])
                    setErrors(res.data.errors);
                  }
                })
              });
        }
    };

    console.log(errors)
    useEffect(() => {
        fetchBookingDetails();
    }, []);


    const filteredData = useMemo(() => {
        let computedData = data;
        if (search) {
            computedData = computedData.filter(
                (booking) =>
                    booking?.user_id.toString().includes(search.toLowerCase()) ||
                    booking?.car_info_id.toString().includes(search.toLowerCase()) ||
                    booking?.pick_up_location.toLowerCase().includes(search.toLowerCase()) ||
                    booking?.drop_off_location.toLowerCase().includes(search.toLowerCase())
                // Add more fields for searching as needed
                // ...
            )
        }
        setTotalItems(computedData.length);

        return computedData.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [data, search, currentPage]);

    return (
        <>
            <section id="content" className="gray-area">
                <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLabel">Update Pending Balance</h3>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="amountInput">Enter Pending Amount</label>
                                <input
                                    type="text"
                                    id="amountInput"
                                    className="form-control"
                                    placeholder="amount"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={enteredAmount}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    data-dismiss="modal"
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="tab-container full-width-style arrow-left dashboard">
                        <div className="tab-content">
                            <div id="dashboard" className="tab-pane fade active in">
                                <h2>BOOKING DETAILS</h2>
                                <div className="filter-section clearfix">
                                    <div className="pull-right col-md-12 action">
                                        <SearchUser
                                            onSearch={(value) => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="table">
                                    <div className="table-container" style={{ marginTop: '28px' }}>
                                        <table className="table table-bordered" width="100%">
                                            <thead>
                                                <th>S.No</th>
                                                <th>Car Info Id</th>
                                                <th>Pick Up Location</th>
                                                <th>Drop Off Location</th>
                                                <th>Action</th>
                                            </thead>
                                            <tbody>
                                                {filteredData.map((booking, index) => (
                                                    <tr key={index}>
                                                        <td>{booking?.id}</td>
                                                        <td>{booking?.car_info_id}</td>
                                                        <td>{booking?.pick_up_location}</td>
                                                        <td>{booking?.drop_off_location}</td>
                                                        <td className="list-action-sec">
                                                            <Link to={`/admin/car-booking-details/${booking?.id}`} className="btn btn-info btn-circle btn-sm mr-2" title="View booking details">
                                                                <i className="fa fa-eye"></i>
                                                            </Link>
                                                            <button type="button" class="btn btn-warning btn-sm" onClick={() => setBookingId(booking?.id)} data-toggle="modal" data-target="#exampleModal">
                                                                Pending Balance
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationComponent
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
                <ToastContainer />
            </section>
        </>
    );
};

export default AdminHocComponent(ViewBookingDetails);
