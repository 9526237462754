import axios from "axios";
import React, { useState } from "react";
import Swal from 'sweetalert2';
import FullPageLoader from "../../common/loader/FullPageLoader";
import AdminHocComponent from "../hoc/AdminHoc";

const AddUser = () => {

    const [userInput, setUserInput] = useState({
        email: '',
        password: '',
        password_confirmation: "",
        first_name: '',
        last_name: '',
        dob: '',
        phone_code: "+91",
        street_address: '',
        street_address_2: '',
        city: '',
        country: "Select Country",
        state: '',
        user_type: '',
        phone_number: '',
        alert_message_type: '',
        zipcode: "",
    });
    const [errors, setErrors] = useState([]);
    const [loader, showLoader, hideLoader] = FullPageLoader();

    const handleInput = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    }

    const submitUser = (e) => {
        e.preventDefault();

        showLoader();

        axios.get('/sanctum/csrf-cookie').then(res => {
            axios.post(`/api/user/register`, userInput).then(res => {
                if (res.data.result) {
                    hideLoader();
                    Swal.fire("success", "User Created Sucessfully Done", "success");
                }
                else {
                    hideLoader();
                    setErrors(res.data.errors);
                }
            });
        });
    }


    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">

                <article className="image-box box innerstyle personal-details">
                    <div className="details">
                        {/* <a href="#" className="button btn-mini pull-right edit-profile-btn">EDIT PROFILE</a> */}
                        <h2 className="box-title fullname">ADD USER</h2>
                    </div>
                </article>

                <div className="card-body">
                    <form onSubmit={submitUser}>
                        <input type="hidden" id="__postSearchLocationAdress" name="street_address" onChange={handleInput} value={userInput.street_address} />
                        {/* <input type="hidden" id="__postSearchLocationAdress2" name="street_address_2" onChange={handleInput} value={userInput.street_address_2} />
                        <input type="hidden" id="__postSearchLocationCity" name="city" onChange={handleInput} value={userInput.city} />
                        <input type="hidden" id="__postSearchLocationState" name="state" onChange={handleInput} value={userInput.state} />
                        */}
                        <input type="hidden" id="__postLatitude" name="latitude" onChange={handleInput} value={userInput.latitude} />
                        <input type="hidden" id="__postLongitude" name="longitude" onChange={handleInput} value={userInput.longitude} />

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="name">First Name</label>
                                    <input type="text" name="first_name" onChange={handleInput} value={userInput.first_name} className="form-control" placeholder="Enter First Name" />
                                    <span className="validate-error-message">{errors.first_name}</span>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="name">Last Name</label>
                                    <input type="text" name="last_name" onChange={handleInput} value={userInput.last_name} className="form-control" placeholder="Enter Last Name" />
                                    <span className="validate-error-message">{errors.last_name}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="userName">User Name</label>
                                    <input type="text" name="user_name" onChange={handleInput} value={userInput.user_name || ''} className="form-control" placeholder="Enter User Name" />
                                    <span className="validate-error-message">{errors.user_name}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" onChange={handleInput} value={userInput.email} className="form-control" placeholder="Enter Email" />
                                    <span className="validate-error-message">{errors.email}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>DOB</label>
                                <input
                                    type="text"
                                    name="dob"
                                    value={userInput.dob}
                                    onChange={handleInput}
                                    className="form-control"
                                    placeholder="mm/dd/yy"
                                    id="dp1659024338042"
                                />
                                <span className="text-danger">
                                    {errors.dob}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" name="password" onChange={handleInput} value={userInput.password} className="form-control" placeholder="Enter Password" />
                                    <span className="validate-error-message">{errors.password}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Re-password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Re-enter your password"
                                    name="password_confirmation"
                                    value={userInput.password_confirmation}
                                    onChange={handleInput}
                                />
                                <span className="text-danger">
                                    {errors.password_confirmation}
                                </span>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <input type="password" name="confirm_password" onChange={handleInput} value={userInput.confirm_password || ''} className="form-control" placeholder="Enter Confirm Password" />
                                    <span className="validate-error-message">{errors.confirm_password}</span>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input type="text" name="company_name" onChange={handleInput} value={userInput.company_name} className="form-control" placeholder="Enter Company Name" />
                                    <span className="validate-error-message">{errors.company_name}</span>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="streetAddress">Street Address</label>
                                    <input type="text" name="street_address" onChange={handleInput} value={userInput.street_address} className="form-control" placeholder="Enter Street Address" />
                                    {/* {isDisplayed &&
                                        <AdminAutoComplete
                                            apiKey={'AIzaSyDhxj1eT2udQsZgkkqkCSkTkAOGv-yiu-Q'}
                                            setCoodinationValueAddress={setCoodinationValueAddress}
                                        />
                                    } */}

                                    <span className="validate-error-message">{errors.street_address}</span>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="streetAddress2">Street Address 2</label>
                                    <input type="text" id="__postSearchLocationAdress2" name="street_address_2" onChange={handleInput} value={userInput.street_address_2} className="form-control" placeholder="Enter Street Address 2" />

                                    <span className="validate-error-message">{errors.street_address_2}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="City">City</label>
                                    <input type="text" id="__postSearchLocationCity" name="city" onChange={handleInput} value={userInput.city} className="form-control" placeholder="Enter City" />

                                    <span className="validate-error-message">{errors.city}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="State">State</label>
                                    <input type="text" id="__postSearchLocationState" name="state" onChange={handleInput} value={userInput.state} className="form-control" placeholder="Enter State" />

                                    <span className="validate-error-message">{errors.state}</span>
                                </div>
                            </div>
                            <div className=" col-md-6">
                                <label>Country</label>
                                <select
                                    className="form-control"
                                    name="country"
                                    value={userInput.country}
                                    onChange={handleInput}
                                >
                                    <option value="">Select Country</option>
                                    <option value="India">India</option>
                                </select>
                                <span className="text-danger">
                                    {errors.country}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input type="text" name="phone_number" onChange={handleInput} value={userInput.phone_number} className="form-control" placeholder="Enter Phone Number" />
                                    <span className="validate-error-message">{errors.phone_number}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Zip Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Zip Code"
                                    name="zipcode"
                                    value={userInput.zipcode}
                                    onChange={handleInput}
                                />
                                <span className="text-danger ">
                                    {errors.zipcode}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="State">User Type</label>
                                    <select name="user_type" onChange={handleInput} value={userInput.user_type} className="form-control" style={{ appearance: "auto" }}>
                                        <option value="">Select User Type</option>
                                        <option value="user">User</option>
                                        <option value="driver">Driver</option>

                                    </select>
                                    <span className="validate-error-message">{errors.user_type}</span>
                                </div>
                            </div>
                            <div className="col-md-6 user-notify-type">
                                <div className="form-group">
                                    <label className="customCheckBox">
                                        <input type="checkbox" name="alert_message_type" onChange={handleInput} value="0" />
                                        <span className="checkmark"></span>Email Alert
                                    </label>
                                    <label className="customCheckBox">
                                        <input type="checkbox" name="alert_message_type" onChange={handleInput} value="1" />
                                        <span className="checkmark"></span>Text Messages
                                    </label>
                                </div>
                                <span className="validate-error-message">{errors.alert_message_type}</span>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 pull-right">
                                <button type="submit" className="button btn-large dark-blue1 btn-admin-panel">Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {loader}
        </div>
    );
}
export default AdminHocComponent(AddUser);