import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (

        <footer id="footer">
            <div className="bottom">
                <div className="container">
                    <div className=" pull-left">
                        <Link to="/" title="Rentalcar - home">
                        <img style={{borderRadius: '50%'}} width={114} height={65} src={"/images/logo3.jpg"} alt="rentalcar" />
                        </Link>
                    </div>
                    <div className="pull-right">
                        <a id="back-to-top" href="#" className="animated" data-animation-type="bounce"><i className="soap-icon-longarrow-up circle" /></a>
                    </div>
                    <div className="copyright pull-right">
                        <p>© 2023-24 Rentalcar</p>
                    </div>
                </div>
            </div>
        </footer>


    );
};

export default Footer;
